import { Box, Grid } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideNavigation from "../../Common/SideNavigation/SideNavigation";
import "./Workspace.css";
import BottomAppBar from "../../Common/BottomAppBar/BottomAppBar";
import MusicBar from "../../Common/MusicBar/MusicBar";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import { css } from "../../../util/common";

const Workspace = ({ selected, scroll, children }) => {
  const { playerState, showQueue, openMini } = React.useContext(PlayerContext);
  const theme = useTheme();
  const matches = {
    small: useMediaQuery(theme.breakpoints.down("md")),
    medium: useMediaQuery(theme.breakpoints.up("md")),
    large: useMediaQuery(theme.breakpoints.up("lg")),
  };

  const { track } = playerState.selectedId ?? {};

  return (
    <div className="Workspace level-2">
      <Grid container spacing={0}>
        {!matches.small && (
          <Grid item xs={3} className="left-grid">
            <SideNavigation selected={selected} />
          </Grid>
        )}
        <Grid item xs={matches.small ? 12 : 9}>
          <Box className={css({ "inner-scroll": 1, "level-3": 1, scroll })}>
            {children}
          </Box>
        </Grid>
      </Grid>
      {matches.small && (
        <>
          <MusicBar
            openMini={openMini}
            track={track}
            showQueue={showQueue}
            playing={playerState.playing}
          />
          <BottomAppBar />
        </>
      )}
    </div>
  );
};

Workspace.defaultProps = {};
export default Workspace;
