import { Person } from "@mui/icons-material";

export const ArtistConfig = {
  fieldMap: {
    ID: (p) => p.ID,
    Thumbnail: (p) => p.Thumbnail,
    Title: (p) => p.Name,
    Caption1: (p) => p.artistName,
    Href1: (p) => `/library/artist/${p.ID}`,
    Caption2: (p) => `${p.TrackCount} tracks`,
  },
  label: "artist",
  logo: Person,
  icon: <Person />,
  gridSort: "Name",
  listSort: "albumFk, discNumber, trackNumber",
  direction: "ASC",
  sorts: [
    {
      label: "Date Added",
      field: "ID",
    },
    {
      label: "Name",
      field: "Name",
    },
    {
      label: "Track Count",
      field: "TrackCount",
    },
  ],
  columns: [
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Album",
      field: "albumName",
    },
    {
      label: "Genre",
      field: "Genre",
    },
  ],
};
