import { QueueMusic } from "@mui/icons-material";
import { createKey } from "../util/common";

export const PlaylistConfig = {
  fieldMap: {
    ID: (p) => createKey(p.Title),
    Thumbnail: (p) => p.track?.albumImage,
    Title: (p) => p.Title,
    Caption1: (p) => false,
    Caption2: (p) => `${p.TrackCount} tracks`,
  },
  label: "playlist",
  logo: QueueMusic,
  icon: <QueueMusic />,
  gridSort: "Title",
  listSort: "trackNumber",
  sorts: [
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Track Count",
      field: "TrackCount",
    },
  ],
  columns: [
    {
      label: "Track Number",
      field: "trackNumber",
    },
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Artist ",
      field: "artistName",
    },
    {
      label: "Album",
      field: "albumName",
    },
    {
      label: "Genre",
      field: "Genre",
    },
  ],
};
