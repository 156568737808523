import { Box, Collapse, Drawer, IconButton } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import useThrottleSearch from "../../../hooks/useThrottleSearch";
import useComponentState from "../../../hooks/useComponentState";
import Control from "../Control/Control";
import "./SearchBar.css";
import { SearchResultItem } from "../GroupSearchBox/GroupSearchBox";
import { Close } from "@mui/icons-material";

const SearchBar = ({ beginTrack }) => {
  const { playerState = {}, setSearchBarState } =
    React.useContext(PlayerContext);
  const { searchbarState } = playerState;
  const { options, processResult, setInputValue, setOptions, busy } =
    useThrottleSearch(beginTrack);
  const { state, setState } = useComponentState();
  const updateValue = (v) => {
    setInputValue(v);
    setState("value", v);
  };
  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={!!searchbarState?.open}
        onClose={() => setSearchBarState(false)}
      >
        <Box style={{ padding: 12 }}>
          <Collapse in={options?.length}>
            <Control.EasyList>
              <li className="easy-header no-line flex center">
                Results for "{state.value}"
                <IconButton
                  className="auto"
                  onClick={() => {
                    setOptions([]);
                    setState("value", "");
                    setInputValue("");
                  }}
                >
                  <Close />
                </IconButton>
              </li>
              {options?.map((o, i) => (
                <SearchResultItem
                  onResultClick={(v) => {
                    setSearchBarState(false);
                    setOptions([]);
                    setState("value", "");
                    processResult(v);
                  }}
                  option={o}
                />
              ))}
            </Control.EasyList>
          </Collapse>
          <Control.TextBox
            fullWidth
            autoFocus
            color={busy ? "error" : "info"}
            label={busy ? `Searching for ${state.value}...` : "Find Music"}
            placeholder="Enter artist, song or album name"
            value={state.value}
            setValue={updateValue}
          />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

SearchBar.defaultProps = {};
export default SearchBar;
