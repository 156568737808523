import { Album, Equalizer, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import "./Control.css";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import GroupSearchBox from "../GroupSearchBox/GroupSearchBox";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Eq = () => {
  return (
    <Box className="auto">
      {" "}
      <Equalizer />
    </Box>
    // <img
    //   src="/assets/eq.gif"
    //   style={{ marginLeft: "auto", width: "24px", height: "24px" }}
    //   alt="eq"
    // />
  );
};

const BlankCover = (props) => {
  return (
    <Avatar {...props} variant="rounded" style={{ width: "100%", height: 240 }}>
      <Album style={{ width: 120, height: 120 }} />
    </Avatar>
  );
};

const TextLink = ({ children, href, state }) => {
  if (!href) return children;
  return (
    <Link className="link" to={href} state={state}>
      {children}
    </Link>
  );
};

const EasyList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  & li {
    width: 100%;
    padding: 8px 12px;
    border-bottom: solid 1px #e0e0e0;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.easy-header {
      position: relative;
      padding: 8px 12px;
      color: #333;
    }
    &.no-line {
      border-bottom: none;
      padding: 0;
    }
    & .avatar {
      margin-right: 12px;
    }
  }
`;

const EasyListItem = ({ icon, title, caption }) => {
  return (
    <Box className="flex center">
      <Avatar className="avatar">{icon}</Avatar>
      <Stack>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="caption">{caption}</Typography>
      </Stack>
    </Box>
  );
};

const SimpleMenu = ({ options, value, icon, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="HoloOrderList">
      <IconButton
        aria-label="settings"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option, i) => (
          <MenuNode
            selected={i === value}
            key={i}
            onClick={() => {
              onClick(i);
              handleClose();
            }}
          >
            {option}
          </MenuNode>
        ))}
      </Menu>
    </div>
  );
};

const MenuNode = styled((props) => {
  const { selected, children, ...other } = props;
  return <MenuItem {...other}>{children}</MenuItem>;
})(({ theme, selected }) => ({
  fontWeight: !selected ? 400 : 600,
}));

const Key = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
};
/**
 * 
    &.tall {
      max-height: calc(100vh - var(--header-offset) - var(--footer-offset));
    }
  max-height: calc(100vh - var(--header-offset));
 */
const TuneList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 12px 0;
  width: 99%;
  min-height: 100vh;
  & li {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    margin-left: 4px;
    width: calc(100% - 24px);
    & div {
      margin-right: 12px;
    }
    & .avatar {
      margin-right: 12px;
    }
    & .caption {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .indicator {
      margin-left: auto;
      margin-right: 24px;
      color: var(--active-link);
    }
    & .menu {
      margin-right: 8px;
    }
    &.selected {
      background-color: var(--color-hilite);
      outline: solid 1px var(--border-hilite);
      border-radius: 4px;
    }
  }

  &.grid {
    min-height: 0;
    & li {
      & div {
        margin-right: 4px;
      }
      float: left;
      width: calc(var(--factor) - 24px);
      overflow: hidden;

      max-height: 240px;
      min-height: 240px;

      display: grid;
      grid-template-areas:
        "photo photo photo"
        "caption fave menu";
      grid-template-rows: var(--thumbnail-image-height) 80px;
      grid-template-columns: var(--tune-grid-columns);

      & .indicator {
        grid-area: fave;
      }
      & .menu {
        grid-area: menu;
      }
      & .caption {
        grid-area: caption;
      }

      & .avatar {
        grid-area: photo;
        width: calc(100% - 8px) !important;
        .MuiAvatar-root {
          width: 100% !important;
          height: 100% !important;
          img {
            width: 100%;
            height: auto;
            max-height: var(--thumbnail-image-height);
          }
        }
      }
    }
  }
`;

const TrackSource = ({ sourceOnly }) => {
  const { playerState, openMini } = React.useContext(PlayerContext);
  const { selectedId } = playerState;
  const navigate = useNavigate();
  if (!selectedId) return <i />;
  const { track, currentType } = selectedId;
  if (!track) return <i />;
  const source =
    currentType === "music" ? "your collection" : `your ${currentType}s`;
  const go = () => {
    openMini(false);
    navigate(`/library/${currentType}`);
  };
  return (
    <Stack className="flex center middle">
      {!sourceOnly && (
        <Typography className="no-wrap" variant="body1">
          {track.Title}
        </Typography>
      )}
      {!!currentType && (
        <Typography onClick={go} className="no-wrap " variant="caption">
          from <u>{source}</u>
        </Typography>
      )}
    </Stack>
  );
};

const ContextDrawer = () => {
  const { drawerState = {}, updateDrawerState } =
    React.useContext(PlayerContext);
  const { open, header, items, onClick, xs, keepOpen } = drawerState;

  const style = !xs
    ? {}
    : {
        float: "left",
        width: `calc(${(xs / 12) * 100}% - var(--footer-offset))`,
      };
  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => updateDrawerState({ open: false })}
        style={{ "--footer-offset": "24px" }}
      >
        {!!items && (
          <EasyList>
            {!!header && <li className="easy-header">{header}</li>}
            {items.map((item, i) => (
              <li
                style={style}
                key={i}
                onClick={() => {
                  !keepOpen && updateDrawerState({ open: false });
                  onClick && onClick(i);
                }}
              >
                {item}
              </li>
            ))}
          </EasyList>
        )}
      </Drawer>
    </React.Fragment>
  );
};

const SearchPanel = (props) => {
  const [on, setIn] = React.useState(false);
  const { onExpose, textOnly } = props;
  const Component = textOnly ? TextBox : GroupSearchBox;
  return (
    <div className="flex center">
      <Collapse in={on || props.exposed} orientation="horizontal">
        <Component
          {...props}
          label="Find music"
          placeholder="Enter artist, song or album"
        />
      </Collapse>
      {!props.exposed && (
        <ExpandMore
          expand={on}
          onClick={() => {
            setIn(!on);
            onExpose && onExpose(!on);
          }}
        >
          <Search />
        </ExpandMore>
      )}
    </div>
  );
};

const TextBox = ({ value, setValue, onChange, ...props }) => {
  const keyup = (e) => {
    const { keyCode } = e;
    keyCode === Key.ENTER && onChange && onChange(value);
  };
  const change = (e) => {
    setValue(e.target.value);
  };
  const args = {
    value,
    onChange: change,
    onKeyUp: keyup,
  };
  return (
    <>
      <TextField {...props} {...args} autoComplete="off" />
    </>
  );
};
const LogoBox = () => {
  return (
    <Box className="flex center" style={{ margin: 8 }}>
      <Avatar
        src="/assets/icon-72x72.png"
        alt="Logo"
        style={{ marginRight: 6 }}
      />
      <Typography variant="body2">
        <b>SkyTunes</b> Cloud Audio Player
      </Typography>
    </Box>
  );
};
const Control = {
  ExpandMore,
  EasyList,
  TuneList,
  SimpleMenu,
  TextBox,
  SearchPanel,
  TextLink,
  ContextDrawer,
  EasyListItem,
  BlankCover,
  LogoBox,
  TrackSource,
  Eq,
};

export default Control;
