import { PlayerContext } from "./usePlayerContext";
import React from "react";
/**
 * controls the size of the TuneList component based on
 * viewport and app state
 */
export default function useScreenSize() {
  const { screenSize } = React.useContext(PlayerContext);
  const { small, medium } = screenSize;

  let gridDivisor;
  if (small) {
    gridDivisor = "50%";
  } else if (medium) {
    gridDivisor = "25%";
  } else gridDivisor = "16%";

  const gridStyle = {
    "--factor": gridDivisor,
  };

  return { gridStyle };
}
