import React from "react";

import { styled } from "@mui/material";
import "./ScrollCouple.css";

const ScrollCoupleHeader = styled("div")`
  width: 100%;
  height: calc(var(--header-large-height) - var(--scroll-offset));
  max-height: calc(var(--header-large-height) - var(--scroll-offset));
  background-color: magenta;
  overflow: hidden;
  position: relative;
  transition: height 0.2s linear;
  & img.scroll-head {
    position: absolute;
    top: -50%;
    left: 0px;
    width: 100%;
    height: auto;
  }
  &.mobile {
    height: calc(var(--header-small-height) - var(--scroll-offset));
    max-height: calc(var(--header-small-height) - var(--scroll-offset));
    & img.scroll-head {
      top: -25%;
    }
  }
`;

const ScrollCouple = ({ small, large, children, ...props }) => {
  const style = {
    "--header-small-height": small + "px",
    "--header-large-height": large + "px",
  };
  return (
    <div style={style}>
      <ScrollCoupleHeader {...props}>{children}</ScrollCoupleHeader>
    </div>
  );
};

ScrollCouple.defaultProps = {};
export default ScrollCouple;
