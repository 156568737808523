import { ExpandMore, MoreVert } from "@mui/icons-material";
import { Box, Drawer, IconButton } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import useTrackMenu from "../../../hooks/useTrackMenu";
import { AudioPlayerEvent } from "../AudioPlayer/AudioPlayer";
import Control from "../Control/Control";
import MusicCard from "../MusicCard/MusicCard";
import "./MiniDrawer.css";

const MiniDrawer = () => {
  const {
    playerState = {},
    openMini,
    nextTrack,
    showQueue,
    showContextDrawer,
  } = React.useContext(PlayerContext);
  const { miniState, muted } = playerState;
  const [progress, setProgress] = React.useState(0);
  const { tuneProps } = useTrackMenu();
  React.useEffect(() => {
    const sub = AudioPlayerEvent.subscribe((time) => {
      setProgress(time);
    });
    return () => sub.unsubscribe();
  });

  return (
    <React.Fragment>
      <Drawer
        classes={{ root: "full-drawer", paper: "full-drawer" }}
        anchor="bottom"
        open={miniState.open}
        onClose={() => openMini(false)}
      >
        {" "}
        <Box className="flex center between">
          {!!playerState.selectedId?.track && (
            <IconButton
              onClick={() =>
                showContextDrawer(tuneProps(playerState.selectedId.track))
              }
            >
              <MoreVert />
            </IconButton>
          )}
          <Control.TrackSource />
          <Box>
            <IconButton onClick={() => openMini(false)}>
              <ExpandMore className="play-button large" />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <MusicCard
            fullWidth
            muted={muted}
            showContextDrawer={showContextDrawer}
            {...playerState.selectedId}
            value={progress}
            nextTrack={nextTrack}
            playing={playerState.playing}
            ready={playerState.ready}
            duration={playerState.duration}
            showQueue={showQueue}
          />
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

MiniDrawer.defaultProps = {};
export default MiniDrawer;
