import { Close, Error } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Collapse,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../../hooks/usePlayerContext";
import Control from "../../Control/Control";
import ListSlider from "../../ListSlider/ListSlider";
import "./QueueDrawer.css";

const QueueDrawer = () => {
  const { playerState, updateQueueState, beginTrack, screenSize } =
    React.useContext(PlayerContext);
  const { selectedId, queueState = {} } = playerState;
  const { queue, track, groupId, type } = selectedId ?? {};
  if (!queue) return <i />;
  const { open } = queueState;
  const index = queue?.indexOf(track);
  const first = queue[index];
  // const shown = queue.slice(index + 1); //
  const items = queue.map((node) => (
    <Box>
      {" "}
      <Stack>
        {!node.trackTime && <Error />}
        {node.Title}
        {!screenSize.small && (
          <Typography variant="caption">{node.artistName}</Typography>
        )}
      </Stack>
    </Box>
  ));
  return (
    <div className="QueueDrawer">
      <React.Fragment>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={() => updateQueueState(false)}
          style={{ "--footer-offset": "24px" }}
        >
          {!!items && (
            <>
              {" "}
              <Control.EasyList className="fixed-list">
                <li className="easy-header">
                  <Box className="flex center wide">
                    <Avatar
                      className="avatar"
                      src={first.albumImage}
                      variant="rounded"
                    />{" "}
                    <Stack ml={2} className="queue-header">
                      <Typography variant="body1" className="no-wrap ">
                        <b>{first.Title}</b>
                      </Typography>
                      <Typography variant="caption" className="no-wrap ">
                        <b>{first.artistName}</b>
                      </Typography>
                    </Stack>
                    <Box className="auto header-button">
                      <IconButton onClick={() => updateQueueState(false)}>
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                </li>
                <li>
                  <ListSlider />
                </li>
              </Control.EasyList>
              <Control.EasyList className="unfixed-list">
                {items.map((item, i) => (
                  <Collapse in={i - 1 >= index}>
                    <li
                      key={i}
                      onClick={() => {
                        beginTrack({ track: queue[i], queue, groupId, type });
                      }}
                    >
                      {item}
                    </li>
                  </Collapse>
                ))}
              </Control.EasyList>
            </>
          )}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

QueueDrawer.defaultProps = {};
export default QueueDrawer;
