import { Album } from "@mui/icons-material";

export const AlbumConfig = {
  fieldMap: {
    ID: (p) => p.ID,
    Thumbnail: (p) => p.Thumbnail,
    Title: (p) => p.Name,
    Caption1: (p) => p.artistName,
    Caption2: (p) => `${p.TrackCount} tracks`,
    Href1: (p) => `/library/artist/${p.artistFk}`,
  },
  label: "album",
  logo: Album,
  icon: <Album />,
  gridSort: "Name",
  listSort: "discNumber,trackNumber",
  direction: "ASC",
  sorts: [
    {
      label: "Date Added",
      field: "ID",
    },
    {
      label: "Title",
      field: "Name",
    },
    {
      label: "Artist",
      field: "artistName",
    },
    {
      label: "Track Count",
      field: "TrackCount",
    },
  ],
  columns: [
    {
      label: "Track Number",
      field: "trackNumber",
    },
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Artist ",
      field: "artistName",
    },
  ],
};
