import React from "react";
import "./TypeSearchBox.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import throttle from "lodash/throttle";
import {
  insertTable,
  searchGroupByType,
} from "../../../connectors/skyTunesApiConnector";
import { Avatar, Box, Button, Collapse, Grid } from "@mui/material";
import Control from "../Control/Control";

const TypeSearchBox = ({
  type = "album",
  field,
  value: boxValue,
  label,
  onSet,
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [value, setValue] = React.useState(boxValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        searchGroupByType(type, request).then(callback);
      }, 200),
    [type]
  );

  React.useEffect(() => {
    let active = true;

    const createNode = {
      ID: -1,
      Name: `Create new ${type}`,
    };

    if (inputValue === "") {
      setOptions(value ? [value] : [createNode]);
      return undefined;
    }

    fetch(inputValue, (results) => {
      const { records } = results ?? { records: [] };
      if (active) {
        let newOptions = [createNode];

        if (value) {
          newOptions = [createNode, value];
        }

        if (records) {
          newOptions = records; // [...newOptions, ...records];
        }

        setOptions(newOptions);
      }
    });
  }, [value, inputValue, fetch, type]);

  return (
    <div className="TypeSearchBox">
      {" "}
      <Collapse in={!editMode}>
        <Autocomplete
          fullWidth
          disablePortal
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.Name || option.Genre
          }
          filterOptions={(x) => x}
          value={value}
          onChange={(event, newValue) => {
            if (newValue.ID === -1) {
              return setEditMode(true);
            }
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            onSet && onSet(field, newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderOption={(props, option) => (
            <Box component="li" className="flex center" {...props}>
              <Avatar
                style={{ marginRight: 8 }}
                variant="rounded"
                src={`${option.Thumbnail}`}
                alt={option.Name}
              />{" "}
              <div
                style={{ maxWidth: "calc(100% - 60px)" }}
                className="no-wrap"
              >
                {option.Name || option.Genre}
              </div>
            </Box>
          )}
          id="combo-box-demo"
          options={options}
          renderInput={(params) => (
            <TextField fullWidth {...params} label={label} />
          )}
        />
      </Collapse>
      <Collapse in={editMode}>
        <TypeSearchBoxNewForm
          save={(o) => {
            setOptions((s) => [...s, o]);
            setInputValue(o);
            onSet && onSet(field, o);
          }}
          cancel={() => setEditMode(false)}
          type={type}
        />
      </Collapse>
    </div>
  );
};
//value, setValue, onChange
function TypeSearchBoxNewForm({ type, save, cancel }) {
  const [vals, setVals] = React.useState({});
  const add = async () => {
    const tables = {
      artist: "s3Artist",
      album: "s3Album",
    };
    const table = tables[type];
    if (!table) return alert(`${type} is not supported`);

    const fieldName = `${type}Image`;
    const payload = {
      [fieldName]: vals.photo,
      Name: vals.name,
    };

    const ID = await insertTable(table, payload);
    save({ ...payload, ID });
    cancel();
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Control.TextBox
          fullWidth
          label={`${type} name`}
          placeholder={`enter a name for the new ${type} `}
          value={vals.name}
          setValue={(v) => setVals((q) => ({ ...q, name: v }))}
        />
      </Grid>
      <Grid item xs={12}>
        <Control.TextBox
          fullWidth
          label={`${type} image`}
          placeholder={`type or paste image location `}
          value={vals.photo}
          setValue={(v) => setVals((q) => ({ ...q, photo: v }))}
        />
      </Grid>
      <Grid item xs={6}>
        <Button variant="outlined" fullWidth onClick={cancel}>
          cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          disabled={!vals.name && vals.photo}
          variant="contained"
          fullWidth
          onClick={add}
        >
          save
        </Button>
      </Grid>
    </Grid>
  );
}

TypeSearchBox.defaultProps = {};
export default TypeSearchBox;
