import React from "react";
import { getGroupById } from "../connectors/skyTunesApiConnector";

export default function usePlayerHistory() {
  const [memory, setMemory] = React.useState([]);

  const getMemory = React.useCallback(() => {
    return JSON.parse(localStorage.getItem("sky-tunes-memory-v4") || "[]");
  }, []);

  const commitMemory = React.useCallback(
    (memObj) => {
      localStorage.setItem("sky-tunes-memory-v4", JSON.stringify(memObj));
      setMemory(memObj.slice(0, 24));
    },
    [setMemory]
  );

  const addToMemory = React.useCallback(
    async (track) => {
      const { label, type, groupId } = track;
      if (!!type && !!groupId && !label) {
        const data = await getGroupById(type, groupId);
        if (!data?.row?.length) return;
        const { row } = data;
        const { Name, Thumbnail, image, Title } = row[0];
        !!(Name || Title) &&
          Object.assign(track, {
            label: Name || Title,
            photo: Thumbnail || image,
          });
        console.log({ data, track });
      }
      const currentMem = [track].concat(
        (getMemory() ?? []).filter((f) => f.ID !== track.ID)
      );
      commitMemory(currentMem);
    },
    [getMemory, commitMemory]
  );

  React.useEffect(() => {
    setMemory(getMemory());
  }, [setMemory, getMemory]);

  return { memory, addToMemory };
}
