import { Home, LibraryBooks, Search } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import "./BottomAppBar.css";
import { PlayerContext } from "../../../hooks/usePlayerContext";

const BottomAppBar = () => {
  const { pageIndex, setSearchBarState } = React.useContext(PlayerContext);
  const navigate = useNavigate();
  return (
    <div className="BottomAppBar">
      <AppBar
        classes={{ root: "black", paper: "black" }}
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar className="flex center">
          <LabelButton
            label="Home"
            color={pageIndex === 0 ? "error" : "primary"}
            onClick={() => navigate("/")}
            aria-label="open drawer"
          >
            <Home />
          </LabelButton>

          <Box sx={{ flexGrow: 1 }} />

          <LabelButton
            label="Search"
            color={pageIndex === 1 ? "error" : "primary"}
            onClick={() => setSearchBarState({ open: true })}
            aria-label="open drawer"
          >
            <Search />
          </LabelButton>

          <Box sx={{ flexGrow: 1 }} />

          <LabelButton
            label="Library"
            color={pageIndex === 2 ? "error" : "primary"}
            onClick={() => navigate("/library")}
            aria-label="open drawer"
          >
            <LibraryBooks />
          </LabelButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const LabelButton = ({ label, children, ...props }) => (
  <Stack style={{ textAlign: "center" }}>
    <IconButton {...props}>{children}</IconButton>
    <Typography
      onClick={props.onClick}
      style={{ color: "white", cursor: "pointer" }}
      variant="caption"
    >
      {label}
    </Typography>
  </Stack>
);

BottomAppBar.defaultProps = {};
export default BottomAppBar;
