import { Box } from "@mui/material";
import React from "react";
import "./Loader.css";

const Loader = ({ children }) => {
  return (
    <div className="Loader wide high flex center middle column">
      <Box>
        <div className="loadingio-spinner-ellipsis-hyc4zo1vieb">
          <div className="ldio-2b54pwhxpd3">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Box>
      {children}
    </div>
  );
};

Loader.defaultProps = {};
export default Loader;
