import { PauseCircle, PlayArrow, QueueMusic } from "@mui/icons-material";
import { Avatar, Stack, Box, Typography, IconButton } from "@mui/material";
import React from "react";
import { AudioRequestEvent } from "../AudioPlayer/AudioPlayer";
import "./MusicBar.css";

const MusicBar = ({ track, playing, showQueue, openMini }) => {
  if (!track) return <i />;
  const Icon = playing ? PauseCircle : PlayArrow;
  return (
    <div className="MusicBar flex center">
      <Avatar
        variant="rounded"
        src={track.albumImage}
        alt={track.Title}
        style={{ marginRight: 8 }}
      />
      <Box
        sx={{ flexGrow: 1 }}
        style={{ maxWidth: "calc(100% - 130px)", overflow: "hidden" }}
        onClick={() => openMini(true)}
      >
        <Typography className="bar-text no-wrap" variant="body1">
          {track.Title}
        </Typography>
        <Typography className="bar-text no-wrap" variant="caption">
          {track.artistName}
        </Typography>
      </Box>

      <Box>
        <IconButton onClick={showQueue}>
          <QueueMusic className="icon-image" />
        </IconButton>

        <IconButton
          style={{ color: "white" }}
          onClick={() => AudioRequestEvent.next({ type: "play" })}
        >
          <Icon className="icon-image" />
        </IconButton>
      </Box>
    </div>
  );
};

MusicBar.defaultProps = {};
export default MusicBar;
