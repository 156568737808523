import AppleConvert from "../util/AppleConvert";
const API_ENDPOINT = "https://u8m0btl997.execute-api.us-east-1.amazonaws.com";
export const getAppleMatches = async ({ title, artist }) => {
  const qs = [title.replace(/\.[^.]{3}/, "")];
  const os = qs.slice(0);
  if (artist?.length) {
    qs.push(artist);
  }
  const address = `${API_ENDPOINT}/apple/${qs.join("+")}`;
  const response = await fetch(address);
  const json = await response.json();
  const first = json?.results?.map((r) => AppleConvert(r));
  if (!first?.length) {
    const retry = `${API_ENDPOINT}/apple/${os.join("+")}`;
    const response = await fetch(retry);
    const json = await response.json();
    return json?.results?.map((r) => AppleConvert(r));
  }
  return first;
};
