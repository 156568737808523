import { MusicNote } from "@mui/icons-material";

export const MusicConfig = {
  fieldMap: {
    ID: (p) => p.ID,
    Thumbnail: (p) => p.albumImage,
    Title: (p) => p.Title,
    Caption1: (p) => p.artistName,
    Href1: (p) => `/library/artist/${p.artistFk}`,
    Caption2: (p) => p.albumName,
    Href2: (p) => `/library/album/${p.albumFk}`,
  },
  label: "music",
  logo: MusicNote,
  icon: <MusicNote />,
  gridSort: "ID",
  listSort: "discNumber,trackNumber",
  direction: "DESC",
  sorts: [
    {
      label: "Date Added",
      field: "ID",
    },
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Artist",
      field: "artistName",
    },
    {
      label: "Album",
      field: "albumName",
    },
  ],
};
