import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Collapse, IconButton, Skeleton, Slider } from "@mui/material";
import { AudioRequestEvent } from "../AudioPlayer/AudioPlayer";
import {
  FastForward,
  FastRewind,
  PauseCircle,
  PlayArrow,
  QueueMusic,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import "./MusicCard.css";
import { getWindowDimensions, mmss } from "../../../util/common";
import Control from "../Control/Control";
import EqBar from "../Bar/EqBar/EqBar";

export function WorkingCardMedia({ image, title, fullWidth, ...props }) {
  const { skeletonSize, width } = getWindowDimensions();
  const [is, setIs] = React.useState(false);
  const skeletonWidth = skeletonSize(0.25, 64);
  const squareSize = fullWidth ? width - 24 : 240;
  React.useEffect(() => {
    const im = new Image();
    im.onload = () => setIs(image);
    im.onerror = () => {
      setIs("/assets/default_album_cover.jpg");
      console.log({ "could not load": image });
    };
    im.src = image;
  }, [image]);
  if (!is) {
    return (
      <Skeleton
        width={skeletonWidth}
        height={skeletonWidth}
        style={{ marginLeft: 16 }}
      />
    );
  }
  return (
    <CardMedia
      height={squareSize}
      {...props}
      component="img"
      image={is}
      alt={title}
      classes={{ root: "tall-100", paper: "tall-100", ...props.classes }}
    />
  );
}

export default function MusicCard({
  track,
  previous,
  ready,
  value,
  duration,
  playing,
  nextTrack,
  showQueue,
  fullWidth,
  muted,
}) {
  const { skeletonSize, width, height } = getWindowDimensions();
  const squareCss = fullWidth
    ? { height: width - 24, maxHeight: height * 0.5 }
    : {};
  // const eqWidth = width - 24;
  const skelFactor = fullWidth ? 1 : 0.25;
  const skeletonWidth = skeletonSize(skelFactor, 24);
  const progressChange = (e, n) => {
    AudioRequestEvent.next({ type: "progress", value: n });
  };
  const VolumeIcon = muted ? VolumeOff : VolumeUp;
  const PlayIcon = playing ? PauseCircle : PlayArrow;
  const className = !!ready && !!previous ? "slidephoto slide" : "slidephoto";
  const firstClass = fullWidth ? "MusicCard full" : "MusicCard";
  const buttonClass = fullWidth ? "play-button large" : "play-button";
  return (
    <Card
      sx={{ margin: "0 12px" }}
      elevation={fullWidth ? 0 : 4}
      className={firstClass}
    >
      {!track ? (
        <Control.BlankCover
          width={skeletonWidth}
          height={skeletonWidth}
          style={{ marginLeft: 16 }}
        />
      ) : (
        <div className="slide-box" style={squareCss}>
          <WorkingCardMedia
            fullWidth={fullWidth}
            classes={{ root: className }}
            image={track.albumImage}
            title={track.Title}
          />
        </div>
      )}

      <Collapse in={!track?.Title}>
        <Control.LogoBox />
      </Collapse>
      <Collapse in={!!track?.Title}>
        <CardContent className="music-card-content">
          <Typography
            gutterBottom
            className={fullWidth ? "no-wrap" : ""}
            variant={fullWidth ? "h6" : "body2"}
            component="div"
          >
            {track?.Title}
          </Typography>

          <Typography
            variant={fullWidth ? "body2" : "caption"}
            className="no-wrap"
            color="text.secondary"
          >
            {track?.artistName} &bull; {track?.albumName}
          </Typography>
        </CardContent>
        <CardContent
          style={{
            marginTop: fullWidth ? 20 : 0,
            marginBottom: fullWidth ? 20 : 0,
          }}
          className="music-card-content flex center"
        >
          {fullWidth && (
            <Box style={{ marginRight: 8, fontSize: "0.8rem" }}>
              {mmss(parseInt(value))}
            </Box>
          )}
          <Slider
            min={0}
            max={duration}
            onChange={progressChange}
            value={value}
          />
          <Box style={{ marginLeft: 8, fontSize: "0.8rem" }}>
            {mmss(parseInt(duration - value))}
          </Box>
        </CardContent>
        {/* {!!track?.Title && (
          <Box className="flex music-card-eq">
            <EqBar width={skeletonWidth} />
          </Box>
        )} */}

        <CardContent className="flex center between music-card-content">
          <Box>
            {/* {!!showContextDrawer && (
              <IconButton onClick={() => showContextDrawer(tuneProps(track))}>
                <MoreVert />
              </IconButton>
            )} */}
            <IconButton
              onClick={() => AudioRequestEvent.next({ type: "mute" })}
            >
              <VolumeIcon />
            </IconButton>
          </Box>
          <IconButton onClick={() => nextTrack(-1)}>
            <FastRewind className={buttonClass} />
          </IconButton>
          <Box>
            <IconButton
              size="large"
              onClick={() => AudioRequestEvent.next({ type: "play" })}
            >
              <PlayIcon className={`${buttonClass} play`} />
            </IconButton>
          </Box>
          <IconButton onClick={() => nextTrack(1)}>
            <FastForward className={buttonClass} />
          </IconButton>
          <Box>
            <IconButton onClick={showQueue}>
              <QueueMusic />
            </IconButton>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
