import { ErrorOutlineRounded, Favorite, MoreVert } from "@mui/icons-material";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import Control from "../Control/Control";
import "./TuneList.css";
import useScrollCouple from "../ScrollCouple/useScrollCouple";
import { css } from "../../../util/common";

const albumCollate = (items, showTitles) => {
  if (!items) return [];
  if (!showTitles) return items;
  return ((o) => {
    let num = "";
    items.map((i) => {
      if (i.albumName !== num) {
        num = i.albumName;
        o.push({ Title: ` ${num}`, isLabel: !0 });
      }
      return o.push(i);
    });
    return o;
  })([]);
};
const discCollate = (items, showMarkers) => {
  if (!items) return [];
  const more = items.find((i) => i.discNumber > 1);
  if (!(more && showMarkers)) return items;
  return ((o) => {
    let num = 0;
    items.map((i) => {
      if (i.discNumber !== num) {
        num = i.discNumber;
        o.push({ Title: `Disc ${num}`, isLabel: !0 });
      }
      return o.push(i);
    });
    return o;
  })([]);
};

const TuneList = ({
  group,
  onClick,
  selectedId,
  menuClick,
  showMarkers,
  showTitles,
  currentType,
  currentId,
  gridType,
  viewGrid,
  state,
  selectedItems,
  unscrollable,
}) => {
  // const { scrollerRef, innerStyle } = useScrollCouple();

  if (!group) {
    return <em>Loading...</em>;
  }

  // const baseClass = viewGrid ? "grid level-5" : "level-5";

  let shown = group;
  if (showMarkers) shown = discCollate(group, showMarkers);
  if (showTitles) shown = albumCollate(group, showTitles);

  return (
    <>
      <Control.TuneList className={css({ unscrollable, grid: viewGrid })}>
        {shown
          ?.filter((g) => !!g)
          .map((tune, i) => (
            <li
              key={i}
              className={
                (!!tune.ID && selectedId === tune.ID) ||
                selectedItems?.some((s) => s.ID === tune.ID)
                  ? "selected"
                  : ""
              }
            >
              {/* list item contents */}
              <TuneListItemContent
                tune={tune}
                state={state}
                type={gridType}
                playing={
                  !!gridType &&
                  gridType === currentType &&
                  currentId?.toString() === tune.ID.toString()
                }
                onClick={onClick}
                group={group}
                menuClick={menuClick}
              />

              {/* list item contents */}
            </li>
          ))}
      </Control.TuneList>
    </>
  );
};

TuneList.defaultProps = {};
export default TuneList;

const TuneListItemContent = ({
  tune,
  onClick,
  group,
  menuClick,
  playing,
  state,
  type,
}) => {
  if (tune.isLabel) {
    return (
      <Box className="wide">
        <Paper style={{ padding: 8 }}>{tune.Title}</Paper>
      </Box>
    );
  }
  const play = () => !!tune.ID && onClick && onClick(tune.ID, group, tune);
  return (
    <>
      <Box className="avatar" onClick={play}>
        <Avatar variant="rounded" src={tune.Thumbnail} alt={tune.Title} />
      </Box>
      <Box className="caption">
        <Stack>
          <Typography
            onClick={play}
            variant="body2"
            className="link subtitle no-wrap flex center"
          >
            {type === "music" && !tune.trackTime && <ErrorOutlineRounded />}
            {tune.Title}{" "}
            {!!playing && (
              <em>
                [[<u>playing</u>]]
              </em>
            )}
          </Typography>
          <Typography variant="caption" className="subtitle no-wrap">
            {!!tune.Caption1 && (
              <TuneListCaption href={tune.Href1} state={state}>
                {tune.Caption1}
              </TuneListCaption>
            )}
            {!!tune.Caption2 && !!tune.Caption1 && <> &bull; </>}
            <TuneListCaption state={state} href={tune.Href2}>
              {tune.Caption2}
            </TuneListCaption>
          </Typography>
        </Stack>
      </Box>
      {tune?.favorite && (
        <Box className="indicator">
          <Favorite />
        </Box>
      )}
      {!!menuClick && (
        <Box className="menu" onClick={() => menuClick && menuClick(tune)}>
          <MoreVert />
        </Box>
      )}
    </>
  );
};

const TuneListCaption = ({ href, children, state }) => {
  if (!href) return <>{children}</>;
  return (
    <Link className="link" to={href} state={state}>
      {children}
    </Link>
  );
};
