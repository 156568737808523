import { MusicNote } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Collapse, IconButton, Typography } from "@mui/material";
import React from "react";
import "./ListTitleBar.css";

const ListTitleBar = ({
  Icon = MusicNote,
  buttons = [],
  tools = [],
  image,
  children,
  hideTitle,
  variant = "h6",
}) => {
  const navigate = useNavigate();
  const args = !image ? {} : { src: image, alt: "logo" };
  return (
    <div className="ListTitleBar">
      <Box className="flex center">
        <Avatar className="header-avatar" {...args}>
          {!image && <Icon />}
        </Avatar>
        <Collapse in={!hideTitle} orientation="horizontal">
          <Typography variant={variant} className="no-wrap">
            {children}
          </Typography>
        </Collapse>
        {tools.map((tool, i) => (
          <Box
            key={i}
            className={i === 0 ? "auto header-button" : "header-button"}
          >
            {tool}
          </Box>
        ))}
        {buttons.map((button, i) => (
          <IconButton
            onClick={() =>
              !!button.href && navigate(button.href, { state: button.state })
            }
            key={i}
            className={i === 0 ? "auto header-button" : "header-button"}
          >
            {button.icon}
          </IconButton>
        ))}
      </Box>
    </div>
  );
};

ListTitleBar.defaultProps = {};
export default ListTitleBar;
