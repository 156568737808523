import React from "react";

// react/MUI deps
import { useNavigate } from "react-router-dom";
import {
  Album,
  Close,
  Edit,
  LocalOffer,
  Person,
  PlaylistAdd,
  QueueMusic,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// react/MUI deps

import Control from "../components/Common/Control/Control";
import { PlayerContext } from "./usePlayerContext";
import TuneCard from "../components/Common/TuneCard/TuneCard";
import { AudioEditEvent } from "./useSkyTunes";

const alpha = (a, b) => (a?.toLowerCase() < b?.toLowerCase() ? 1 : -1);

export default function useTrackMenu(state) {
  const {
    showContextDrawer,
    addToQueue,
    playlistTitles,
    matchTrack,
    addToList,
    editTrack,
    notify,
    openMini,
    updateDrawerState,
    getKeys,
  } = React.useContext(PlayerContext);
  const navigate = useNavigate();
  const [titles, setTitles] = React.useState(playlistTitles);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const showPlaylist = (tune) => {
    const existingLists = matchTrack(tune);
    const listProps = {
      header: (
        <Box className="flex center wide">
          <>Add {tune.Title} to Playlist: </>
          <IconButton
            className="auto header-button"
            onClick={() => updateDrawerState({ open: false })}
          >
            <Close />
          </IconButton>
        </Box>
      ),
      keepOpen: true,
      onClick: async (j) => {
        const listTitle = playlistTitles[j];
        await addToList(listTitle, tune);
        const names = await getKeys();
        setTitles(names);
        AudioEditEvent.next(listTitle);
        notify(`${tune.Title} added to ${listTitle}`);
      },
      xs: small ? 3 : 2,
      items: titles
        .sort(alpha)
        .map((b) => (
          <Box
            style={{ color: existingLists.indexOf(b) > -1 ? "red" : "gray" }}
          >
            {b}
          </Box>
        )),
    };
    showContextDrawer(listProps);
  };

  const tuneProps = (tune) => {
    const tuneMenu = [
      {
        icon: <Person />,
        title: "View Artist",
        caption: tune.artistName,
        close: !0,
        action: () => navigate(`/library/artist/${tune.artistFk}`, { state }),
      },
      {
        icon: <Album />,
        title: "View Album",
        caption: tune.albumName,
        close: !0,
        action: () => navigate(`/library/album/${tune.artistFk}`, { state }),
      },
      {
        icon: <LocalOffer />,
        title: "View Genre",
        caption: tune.Genre,
        close: !0,
        action: () => navigate(`/library/genre/${tune.Genre}`, { state }),
      },
      {
        icon: <Edit />,
        title: "Edit this track",
        caption: "Open the track editor",
        action: () => editTrack(tune),
      },
      {
        icon: <QueueMusic />,
        title: "Add to queue",
        caption: "Play this song next",
        action: () => addToQueue(tune),
      },
      {
        icon: <PlaylistAdd />,
        title: "Add to playlist",
        caption: "Save this song to play later",
        action: () => showPlaylist(tune),
      },
    ];
    const args = {
      Thumbnail: tune.albumImage,
      noCaption: !0,
    };
    return {
      header: (
        <Box className="flex  middle wide">
          <TuneCard {...args} />
          <IconButton
            className="force-right header-button"
            onClick={() => updateDrawerState({ open: false })}
          >
            <Close />
          </IconButton>
        </Box>
      ),
      onClick: (j) => {
        tuneMenu[j].action();
        tuneMenu[j].close && openMini(false);
      },
      items: tuneMenu
        .filter((e) => !!e.caption)
        .map((f, i) => <Control.EasyListItem key={i} {...f} />),
    };
  };

  return { tuneProps };
}
