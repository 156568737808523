import React from "react";

import throttle from "lodash/throttle";
import { serialSearch } from "../connectors/skyTunesApiConnector";
import { useNavigate } from "react-router-dom";

const collate = (result) => {
  if (!result?.records?.length) return;
  const { records, type } = result;
  const shown = records.slice(0, 2);
  const out = [
    {
      Name: `${type} results`,
      ID: -1,
    },
  ];
  shown.map((item) => {
    switch (type) {
      case "music":
        out.push({
          ...item,
          Name: item.Title,
          Thumbnail: item.albumImage,
          caption: item.artistName,
          type,
        });
        break;
      case "artist":
        out.push({ ...item, type, caption: `${item.TrackCount} tracks` });
        break;
      case "album":
        out.push({ ...item, type, caption: item.artistName });
        break;
      default:
      // do nothing;
    }
    return item;
  });
  records.length > 2 &&
    out.push({
      Name: `See all ${records.length} ${type} results`,
      ID: -1,
    });

  return out;
};

export default function useThrottleSearch(beginTrack) {
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [busy, setBusy] = React.useState(false);
  const navigate = useNavigate();

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        setBusy(true);
        serialSearch(request).then((a) => {
          callback(a);
          setBusy(false);
        });
      }, 1200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    inputValue &&
      inputValue.length > 2 &&
      fetch(inputValue, (results) => {
        console.log({ results });
        let opts = [];
        !!results &&
          results.map((x) => {
            const part = collate(x);
            !!part && (opts = [...opts, ...part]);
            return x;
          });
        // const { records } = results ?? { records: [] };

        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (opts.length) {
            newOptions = [...opts];
          }

          setOptions(newOptions);
        }
      });
  }, [value, inputValue, fetch]);

  const processResult = (track) => {
    if (track.type === "music") {
      beginTrack({ track, queue: [track] });
      return;
    }
    if (track.ID < 0) return navigate(`/search/${inputValue}`);
    navigate(`/library/${track.type}/${track.ID}`);
  };

  return {
    inputValue,
    value,
    setOptions,
    setValue,
    setInputValue,
    options,
    busy,
    processResult,
  };
}
