import React from "react";
import { currentBarGraph } from "../audio/AudioAnalyser";
import Observer from "../util/Observer";

const AudioContext =
  window.AudioContext || // Default
  window.webkitAudioContext; // Safari a

export const audioGraphUpdate = new Observer("audio-graph");

export default function useAudioContext(width) {
  const [context, setContext] = React.useState(new AudioContext());
  const [analist, setAnalist] = React.useState(null);

  const connect = React.useCallback((audio) => {
    const ctx = new AudioContext();
    const analyser = ctx.createAnalyser();
    const source = ctx.createMediaElementSource(audio);
    source.connect(analyser);
    analyser.connect(ctx.destination);
    setAnalist(analyser);
    setContext(ctx);
    console.log("Connected");
  }, []);

  const start = React.useCallback(() => {
    const exec = () => {
      const graph = currentBarGraph(analist, width);
      console.log({ width });
      audioGraphUpdate.next(graph);
      window.requestAnimationFrame(exec);
    };
    !!analist && window.requestAnimationFrame(exec);
    console.log("animation started", width);
  }, [analist, width]);

  const disconnect = React.useCallback(() => {
    console.log("Disconnected");
    setAnalist(null);
    setContext(null);
  }, []);

  // React.useEffect(() => {
  //   !!analist && start();
  // }, [analist, start]);

  return { disconnect, connect, start, analist, context };
}
