import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { GridConfig } from "../../../config";
import { css } from "../../../util/common";
import "./TypeMenu.css";

const types = ["artist", "genre", "album", "playlist"];

const TypeMenu = ({ type, small }) => {
  const emptyCategory = !type || type === "music";
  return (
    <div className="TypeMenu flex center">
      <Box
        className={css({
          "type-button": 1,
          off: emptyCategory,
        })}
      >
        <Link to="/library">
          <IconButton style={{ marginLeft: 12 }}>
            <Close />
          </IconButton>
        </Link>
      </Box>
      {types.map((typeName, i) => {
        const off = !emptyCategory && type !== typeName;
        const args =
          type === typeName
            ? { color: "success", variant: "filled" }
            : { color: "primary", variant: "outlined" };
        return (
          <Box
            className={css({
              "type-menu": 1,
              off,
              small,
            })}
          >
            <Link className="link" key={i} to={`/library/${typeName}`}>
              {" "}
              <Chip
                label={small ? "" : <>{typeName}</>}
                size={small ? "small" : "medium"}
                icon={GridConfig[typeName].icon}
                {...args}
                className={small ? "header-chip tiny" : "header-chip"}
              />
            </Link>
          </Box>
        );
      })}
    </div>
  );
};

TypeMenu.defaultProps = {};
export default TypeMenu;
