import React from "react";
import "./GroupSearchBox.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import useThrottleSearch from "../../../hooks/useThrottleSearch";
import { css } from "../../../util/common";

const GroupSearchBox = ({ beginTrack, ...props }) => {
  const {
    busy,
    value,
    options,
    setValue,
    inputValue,
    setOptions,
    setInputValue,
    processResult,
  } = useThrottleSearch(beginTrack);

  return (
    <div className="GroupSearchBox flex center">
      <Autocomplete
        freeSolo
        includeInputInList
        loadingText={`Searching for ${inputValue}...`}
        loading={busy}
        noOptionsText="Hang on..."
        fullWidth
        disablePortal
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.Name || option.Genre
        }
        filterOptions={(x) => x}
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          processResult(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderOption={(props, option) => (
          <SearchResultItem option={option} {...props} />
        )}
        id="combo-box-demo"
        options={options}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            size="small"
            error={busy}
            label="Find Music"
            placeholder="Enter artist, song or album"
          />
        )}
      />
    </div>
  );
};

GroupSearchBox.defaultProps = {};
export default GroupSearchBox;

export const SearchResultItem = ({ option, ...props }) => (
  <li className={css({ "easy-header": option.ID < 0, "no-line": 1 })}>
    <Box
      onClick={() => {
        props.onResultClick && props.onResultClick(option);
      }}
      component="li"
      className="flex center"
      {...props}
    >
      {!!option.Thumbnail && (
        <Avatar
          style={{ marginRight: 8 }}
          variant="rounded"
          src={`${option.Thumbnail}`}
          alt={option.Name}
        />
      )}
      <Stack style={{ maxWidth: "calc(100% - 60px)" }} className="no-wrap">
        <Typography variant="body2">{option.Name}</Typography>
        {!!option.caption && (
          <Typography variant="caption">{option.caption}</Typography>
        )}
      </Stack>
    </Box>
  </li>
);
