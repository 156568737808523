import { Download } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { client } from "../../../constants";
import Control from "../Control/Control";
import "./TubeSocket.css";

const SOCKET_URI =
  "wss://0wzd2p35c1.execute-api.us-east-1.amazonaws.com/production";

class TubeSocket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      ws: null,
      data: null,
      showTextbox: true,
    };
    this.openListener = this.openListener.bind(this);
    this.messageListener = this.messageListener.bind(this);
    this.closeListener = this.closeListener.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
  }

  onComplete() {
    const { onComplete } = this.props;
    onComplete && onComplete();
  }

  messageListener(message) {
    const { data } = message;
    const json = JSON.parse(data);
    console.log({ json });
    !!json && this.setState({ ...json });
    !!json.complete && this.onComplete();
  }

  closeListener() {
    console.log("You are disconnected.");
    this.setState({
      connected: false,
      ws: null,
      readyState: client.readyState,
    });
    if (this.state.logout) {
      return console.log("Reconnection will not be attempted");
    }
    this.retryConnection();
  }

  openListener() {
    console.log("You are connected");
    this.setState({ ws: client, connected: true });
    this.sendMessage({
      action: "introduce",
      data: {
        type: "agent",
      },
    });
  }

  sendMessage(json) {
    client.readyState === 1 && client.send(JSON.stringify(json));
    client.readyState !== 1 &&
      console.log("Cannot send message", client.readyState);
  }

  sendCommand(id) {
    this.sendMessage({
      action: "exec",
      data: {
        id,
      },
    });
    this.setState({ clicked: 1, complete: !1, downloadIndex: 0 });
  }

  mountClient() {
    console.log("Mounting client");
    client.addEventListener("open", this.openListener);
    client.addEventListener("message", this.messageListener);
    client.addEventListener("close", this.closeListener);
  }

  retryConnection() {
    setTimeout(() => {
      console.log("Retrying connection...");
      client = new WebSocket(SOCKET_URI);
      this.mountClient();
    }, 5000);
  }

  componentWillUnmount() {
    console.log("Disconnecting.");
    client.removeEventListener("open", this.openListener);
    client.removeEventListener("message", this.messageListener);
    client.removeEventListener("close", this.closeListener);
    this.sendMessage({
      action: "disconnect",
    });
  }
  componentDidMount() {
    console.log("Component is mounted");
    this.mountClient();
  }

  parseTube(uri) {
    const listReg = /list=(.{34})/;
    const tubeReg = /v=(.{11})/;
    const mobiReg = /be\/(.{11})/;
    const listKey = listReg.exec(uri);
    const tubeKey = tubeReg.exec(uri);
    const mobiKey = mobiReg.exec(uri);
    console.log({ mobiKey });
    if (listKey) return listKey[1];
    if (mobiKey) return mobiKey[1];
    if (tubeKey) return tubeKey[1];
  }

  render() {
    const {
      connected,
      working,
      thumb,
      title,
      status,
      // publicURL,
      complete,
      clicked,
      tubeKey,
      uri,
      uploadProgress,
      error,
      uploadSize,
      downloadTotal,
      downloadIndex,
      // queue,
    } = this.state;
    const inProgress = working && !complete;
    // const isWaiting = clicked && !working;
    // let current = !(queue && downloadIndex) ? {} : queue[downloadIndex - 1];
    let listProgress;
    if (!!downloadTotal && downloadIndex > 0 && downloadTotal > 1) {
      listProgress = (downloadIndex / downloadTotal) * 100;
    }
    if (!connected)
      return (
        <>
          <Alert severity="info">Waiting to connect...</Alert>
          <pre>{JSON.stringify(this.state, 0, 2)}</pre>
        </>
      );

    return (
      <>
        <Box className="TubeSocket">
          <Control.TextBox
            autoFocus
            fullWidth={!uri}
            size="small"
            disabled={inProgress || clicked || !connected}
            label="Enter YouTube URL"
            value={uri}
            setValue={(uri) => {
              const tubeKey = this.parseTube(uri);
              this.setState({ uri, tubeKey });
            }}
            onChange={(v) => {
              const id = this.parseTube(v);
              !!id && this.sendCommand(id);
            }}
          />

          {!!uri && (
            <Button
              variant="outlined"
              disabled={inProgress || clicked || !tubeKey}
              onClick={() => {
                const key = this.parseTube(uri);
                !!key && this.sendCommand(key);
                !key && alert("URI not set");
              }}
            >
              download
              <Download className="button-icon" />
            </Button>
          )}
        </Box>

        <Stack>
          {!!thumb && (
            <img src={thumb} alt="preview" className="socket-preview" />
          )}
          <Typography gutterBottom variant="body1" component="div">
            {title}
          </Typography>
          <Typography gutterBottom variant="caption" component="div">
            {status}
          </Typography>
          {!!uploadProgress && !error && (
            <Stack>
              {uploadProgress > 0 && (
                <Typography
                  gutterBottom
                  variant="caption"
                  className="socket-status"
                  component="div"
                >
                  {uploadProgress}% of {uploadSize} downloaded.
                </Typography>
              )}
              <LinearProgress
                value={uploadProgress}
                variant={uploadProgress > 0 ? "determinate" : "indeterminate"}
              />

              {!!listProgress && (
                <>
                  <Typography
                    gutterBottom
                    variant="caption"
                    className="socket-status"
                    component="div"
                  >
                    {downloadIndex} of {downloadTotal} downloaded.
                  </Typography>
                  <LinearProgress value={listProgress} variant="determinate" />
                </>
              )}
            </Stack>
          )}
        </Stack>
      </>
    );
  }
}

TubeSocket.defaultProps = {};
export default TubeSocket;
