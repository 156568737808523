import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AppNotifier({
  open = false,
  setOpen,
  severity = "info",
  message,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen && setOpen(false);
  };
  const origin = { vertical: "top", horizontal: "left" };
  return (
    <Snackbar
      anchorOrigin={origin}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={severity}> {message} </Alert>
    </Snackbar>
  );
}
