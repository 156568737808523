import { Drawer } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import TubeSocket from "../TubeSocket/TubeSocket";
import "./SocketDrawer.css";

const SocketDrawer = () => {
  const { playerState = {}, setSocketOpen } = React.useContext(PlayerContext);
  const { socketOpen } = playerState;
  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={!!socketOpen}
        onClose={() => setSocketOpen(false)}
      >
        {" "}
        {socketOpen && <TubeSocket />}
      </Drawer>
    </React.Fragment>
  );
};

SocketDrawer.defaultProps = {};
export default SocketDrawer;
