import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { WorkingCardMedia } from "../MusicCard/MusicCard";
import Control from "../Control/Control";

export default function TuneCard({
  Title,
  Thumbnail,
  Caption1,
  Caption2,
  selected,
  noCaption,
}) {
  return (
    <Card elevation={selected ? 5 : 2} style={{ maxWidth: "240px" }}>
      <CardActionArea>
        <WorkingCardMedia
          component="img"
          height="100%"
          style={{ maxWidth: "240px" }}
          image={Thumbnail}
          alt={Title}
        />
        {!noCaption && (
          <>
            <CardContent>
              <Typography
                gutterBottom
                className="no-wrap  flex"
                variant="body2"
                component="div"
              >
                {Title} {selected && <Control.Eq />}
              </Typography>
              <Typography
                variant="subtitle1"
                className="no-wrap"
                color="text.secondary"
              >
                {Caption1}
              </Typography>
              {!!Caption2 && (
                <Typography
                  variant="caption"
                  className="no-wrap"
                  color="text.secondary"
                >
                  {Caption2}
                </Typography>
              )}
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  );
}
