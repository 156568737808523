import React from "react";

// @material stuff
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// actual components...move elsewhere
// import { Avatar, Box, IconButton, Typography } from "@mui/material";
// import { Close } from "@mui/icons-material";

// local deps
import usePlaylist from "./usePlaylist";
import { PLAYER_LOAD_DELAY } from "../constants";
import Observer from "../util/Observer";
import usePlayerHistory from "./usePlayerHistory";
// import ListSlider from "../components/Common/ListSlider/ListSlider";
import useAudioContext from "./useAudioContext";
import { getWindowDimensions } from "../util/common";

export const AudioEditEvent = new Observer("editor");

export default function useSkyTunes() {
  const { getKeys, playlistKeys, playlistTitles, matchTrack, addToList } =
    usePlaylist();
  const { addToMemory } = usePlayerHistory();
  const theme = useTheme();
  const screenSize = {
    small: useMediaQuery(theme.breakpoints.down("md")),
    medium: useMediaQuery(theme.breakpoints.up("md")),
    large: useMediaQuery(theme.breakpoints.up("lg")),
  };
  const [pageIndex, setPageIndex] = React.useState(0);
  const [playerState, setPlayerState] = React.useState({
    value: "Eureka!!",
    headerVisible: true,
    drawerState: {},
    socketOpen: false,
    working: false,
    viewGrid: false,
    notifierState: { open: false },
    editorState: { open: false },
    miniState: { open: false },
    sitRepState: { open: false },
    searchbarState: { open: false },
    queueState: { open: false },
  });

  const { skeletonSize } = getWindowDimensions();
  const skelFactor = screenSize.small ? 1 : 0.25;
  const skeletonWidth = skeletonSize(skelFactor, 36);
  const { disconnect, connect, context } = useAudioContext(skeletonWidth);
  const updateQueueState = (open) => {
    setPlayerState((s) => ({
      ...s,
      queueState: {
        open,
      },
    }));
  };

  const setSearchBarState = (open) => {
    setPlayerState((s) => ({
      ...s,
      searchbarState: {
        open,
      },
    }));
  };

  const openMini = (open) => {
    setPlayerState((s) => ({
      ...s,
      miniState: {
        open,
      },
    }));
  };

  const sitrep = (message) => {
    setPlayerState((s) => ({
      ...s,
      sitRepState: {
        open: !!message,
        message,
      },
    }));
  };

  const setSitRepState = (v) => {
    setPlayerState((s) => ({
      ...s,
      sitRepState: v,
    }));
  };

  const editTrack = (track) => {
    setPlayerState((s) => ({
      ...s,
      drawerState: { open: false },
      editorState: {
        open: !0,
        track,
        setOpen: (f) =>
          setPlayerState((s) => ({ ...s, editorState: { open: f } })),
      },
    }));
  };

  const notify = (message, severity = "info") => {
    setPlayerState((s) => ({
      ...s,
      notifierState: {
        open: !0,
        severity,
        message,
        setOpen: (f) =>
          setPlayerState((s) => ({ ...s, notifierState: { open: false } })),
      },
    }));
  };

  const showContextDrawer = (props) => {
    setPlayerState((s) => ({
      ...s,
      drawerState: { ...props, open: true },
    }));
  };
  const setSocketOpen = (value) => {
    setPlayerState((s) => ({ ...s, socketOpen: value }));
  };
  const setGridView = (value) => {
    setPlayerState((s) => ({ ...s, viewGrid: value }));
  };
  const setPlayerBusy = (value) => {
    setPlayerState((s) => ({ ...s, working: value }));
  };
  const updateDrawerState = (value) => {
    if (!value) openMini(value);
    setPlayerState((s) => ({ ...s, drawerState: value }));
  };
  const updatePlayerState = (key, value) => {
    setPlayerState((s) => ({ ...s, [key]: value }));
  };
  const beginTrack = React.useCallback(
    (data) => {
      const {
        type: currentType,
        groupId: currentId,
        track,
        photo,
        label,
      } = data;

      console.log({ currentType, currentId });

      updatePlayerState("ready", false);
      updatePlayerState("selectedId", { ...data, currentType, currentId });
      !!track &&
        addToMemory({
          ...track,
          photo,
          label,
          type: currentType,
          groupId: currentId,
        });
      setTimeout(() => {
        updatePlayerState("ready", true);
      }, PLAYER_LOAD_DELAY);
    },
    [addToMemory]
  );
  const addToQueue = React.useCallback(
    (tune) => {
      const { queue, track } = playerState.selectedId;
      const index = queue?.indexOf(track) + 1;
      const reformed = queue
        .slice(0, index)
        .concat([{ ...tune, inserted: !0 }])
        .concat(queue.slice(index));
      updatePlayerState("selectedId", {
        ...playerState.selectedId,
        queue: reformed,
      });
    },
    [playerState]
  );
  const onApplicationLoaded = React.useCallback(async () => {
    await getKeys();
    console.log("Application loaded");
  }, [getKeys]);
  // load group info when component loads
  React.useEffect(() => {
    onApplicationLoaded();
  }, [onApplicationLoaded]);

  const onNext = React.useCallback(
    (next) => {
      console.log(" >> Firing next event.");
      beginTrack(next);
    },
    [beginTrack]
  );
  const nextTrack = React.useCallback(
    (index) => {
      const { track, queue, groupId, type } = playerState.selectedId ?? {
        track: {},
      };
      let currentIndex = queue.indexOf(track);
      if (currentIndex < queue.length) {
        currentIndex += index;
        const queuedTrack = queue[currentIndex];
        // set progress value to 1 for next track
        console.log(" >> Sending next track signal %s", new Date().getTime());
        onNext &&
          onNext({ queue, track: queuedTrack, previous: track, groupId, type });
      }
    },
    [playerState, onNext]
  );

  const showQueue = () => {
    updateQueueState({ open: true });
    // const { queue, track, groupId, type } = playerState.selectedId ?? {};
    // console.log({ queue });
    // const index = queue?.indexOf(track);
    // const first = queue[index];
    // const shown = queue.slice(index + 1);
    // const items = [<ListSlider />].concat(
    //   shown.map((node) => <Box>{node.Title}</Box>)
    // );
    // const queueProps = {
    //   header: (
    //     <Box className="flex center wide">
    //       <Avatar className="avatar" src={first.albumImage} variant="rounded" />
    //       <Typography variant="body1">
    //         Now Playing: <b>{first.Title}</b>
    //       </Typography>
    //       <Box className="auto header-button">
    //         <IconButton onClick={() => updateDrawerState({ open: false })}>
    //           <Close />
    //         </IconButton>
    //       </Box>
    //     </Box>
    //   ),
    //   onClick: (j) => {
    //     if (j < 1) return;
    //     beginTrack({ track: queue[index + j], queue, groupId, type });
    //   },
    //   items,
    // };
    // showContextDrawer(queueProps);
  };

  const { drawerState } = playerState;

  return {
    addToList,
    addToQueue,
    AudioEditEvent,
    beginTrack,
    drawerState,
    editTrack,
    getKeys,
    matchTrack,
    nextTrack,
    notify,
    onNext,
    openMini,
    pageIndex,
    playerState,
    playlistKeys,
    playlistTitles,
    screenSize,
    sitrep,
    setGridView,
    setPageIndex,
    setPlayerBusy,
    setSearchBarState,
    setSitRepState,
    setSocketOpen,
    showContextDrawer,
    showQueue,
    updatePlayerState,
    updateDrawerState,
    updateQueueState,
    skeletonWidth,
    disconnect,
    connect,
    context,
  };
}
