import React from "react";
import { fullGrid } from "../../../../audio/EqLabel";
import { audioGraphUpdate } from "../../../../hooks/useAudioContext";
import "./EqBar.css";

const EqBar = ({ width }) => {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const sub = audioGraphUpdate.subscribe(setData);
    return () => sub.unsubscribe();
  }, []);
  return (
    <div className="EqBar flex debug" style={{ width }}>
      <div
        style={{
          backgroundImage: fullGrid(),
          backgroundSize: `${width}px 40px`,
          backgroundRepeat: "no-repeat",
        }}
        className="grid-mask"
      ></div>
      {data.map((coord, i) => {
        return (
          <div
            style={{
              height: coord.actualHeight + "px",
              width: coord.barWidth + "px",
              minWidth: coord.barWidth + "px",
              marginTop: "auto",
              backgroundColor: coord.fillStyle,
              zIndex: 1,
            }}
            key={i}
          ></div>
        );
      })}
    </div>
  );
};

EqBar.defaultProps = {};
export default EqBar;
