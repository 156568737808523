import React from "react";
import "./AuthScreen.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

const AuthScreen = () => {
  return <div className="AuthScreen"> hello</div>;
};

AuthScreen.defaultProps = {};
export default withAuthenticator(AuthScreen);
