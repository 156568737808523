import React from "react";
import ListTitleBar from "../../Common/ListTitleBar/ListTitleBar";
import TuneList from "../../Common/TuneList/TuneList";
import useGroupList from "./useGroupList";
import ScrollCouple from "../../Common/ScrollCouple/ScrollCouple";
import useScrollCouple from "../../Common/ScrollCouple/useScrollCouple";

import { ArrowForward, SortByAlpha } from "../../../compatibility/icons";
import { Box, Chip, IconButton } from "../../../compatibility/components";
import "./GroupList.css";
import ListSlider from "../../Common/ListSlider/ListSlider";

const GroupList = (props) => {
  const { type = "none", id: groupId } = props;
  const {
    state,
    screenSize,
    showContextDrawer,
    tuneProps,
    Name,
    Title,
    Genre,
    imageLg,
    basicStyle,
    navigateDest,
    allPlay,
    PlayIcon,
    playLabel,
    isPlaying,
    contextProps,
    locationState,
    playerState,
    playTune,
  } = useGroupList(props);
  const { outerStyle, scrollerRef, innerStyle } = useScrollCouple();
  return (
    <div className="GroupList level-4" style={outerStyle}>
      <Box className={basicStyle}>
        <ListTitleBar
          buttons={[
            {
              state: locationState,
              href: navigateDest,
              icon: <ArrowForward />,
            },
          ]}
        >
          {state.detailObject && <>{Title || Name || Genre}</>}
        </ListTitleBar>
        {!!imageLg && (
          <ScrollCouple
            small={160}
            large={300}
            className={screenSize.small ? "mobile" : ""}
          >
            <img className="scroll-head" src={imageLg} alt={Title} />
          </ScrollCouple>
        )}
        <Box className="flex center">
          <Chip
            variant="filled"
            color={isPlaying ? "error" : "success"}
            className="header-chip"
            onClick={allPlay}
            label={
              <div className="flex center">
                {" "}
                {playLabel} <PlayIcon className="button-icon" />
              </div>
            }
          ></Chip>
          <Box className="header-chip">{state.group?.length} tracks</Box>

          <Box className="auto header-button">
            <IconButton onClick={() => showContextDrawer(contextProps)}>
              <SortByAlpha />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <div className="level-5" style={innerStyle} ref={scrollerRef}>
        <TuneList
          state={{ type, id: groupId }}
          showMarkers={type === "album"}
          showTitles={type === "artist"}
          gridType={type}
          menuClick={(tune) => {
            showContextDrawer(tuneProps(tune));
          }}
          group={state.group}
          selectedId={playerState?.selectedId?.track?.ID}
          onClick={(id, queue, track) => playTune(queue, track)}
        />
      </div>
    </div>
  );
};

GroupList.defaultProps = {};
export default GroupList;
