import { Avatar, Box, Slider, Tooltip } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import { mmss } from "../../../util/common";
import { AudioPlayerEvent } from "../AudioPlayer/AudioPlayer";
import "./ListSlider.css";

const ListSlider = () => {
  const { playerState, beginTrack, screenSize } =
    React.useContext(PlayerContext);
  const { selectedId } = playerState;
  const { queue, track, groupId, type, photo, label } = selectedId ?? {
    track: {},
  };
  const [progress, setProgress] = React.useState(0);

  const timeTilNow = React.useCallback(
    (ordinal) => {
      const index = ordinal || queue.indexOf(track);
      return sum(queue.slice(0, index).map((x) => x.trackTime / 1000));
    },
    [queue, track]
  );

  const play = React.useCallback(
    (updatedTrack) => {
      console.log([updatedTrack]);
      beginTrack({ queue, track: updatedTrack, groupId, type, photo, label });
    },
    [queue, beginTrack, groupId, type, photo, label]
  );

  React.useEffect(() => {
    const sub = AudioPlayerEvent.subscribe((time) => {
      const sofar = timeTilNow();
      setProgress(parseInt(time + sofar));
    });
    return () => sub.unsubscribe();
  });
  const handleSliderChange = (event, newValue) => {
    const before = markers.filter((f, k) => f.value < newValue);
    const track = before.pop();
    console.log(track.data.i);
    let sofar = 0;
    if (track) {
      const index = queue.indexOf(track.data.i);
      if (index < 0) return alert(index);
      sofar = timeTilNow(index);
      console.log({ index, sofar });
    }
    const startIndex = sofar;
    console.log({ newValue, sofar, startIndex, track: track.data.i });
    //   sessionStorage.setItem("currentTime", startIndex);
    play(track.data.i);
  };
  if (!queue?.length) return <i>no items</i>;
  const ok = queue.every((c) => !!c.trackTime);
  if (!ok) return <i>missing track times</i>;
  const all = parseInt(sum(queue.map((x) => x.trackTime / 1000)));
  const width = queue.length < 25 && !screenSize.small ? 24 : 16;
  const markers = ((t, o) => {
    queue.map((i) => {
      const j = {
        value: o / 1000,
        data: { i },
        label:
          (screenSize.small && queue.length > 25) ||
          (!screenSize.small && queue.length > 75) ? null : (
            <Avatar
              src={i.albumImage}
              alt={i.Title}
              sx={{ width, height: width }}
            >
              {i.Title.substr(0, 1)}
            </Avatar>
          ),
      };
      o += i.trackTime;
      return t.push(j);
    });
    return t;
  })([], 0);

  return (
    <div className="ListSlider flex center between">
      <TimeBox>{mmss(progress)}</TimeBox>
      <Slider
        aria-label="Custom marks"
        min={0}
        max={all}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        value={progress}
        marks={markers}
      />
      <TimeBox>{mmss(all)}</TimeBox>
    </div>
  );
};

ListSlider.defaultProps = {};
export default ListSlider;

const sum = (a) => a.reduce((a, b) => a + b, 0);

const TimeBox = ({ children }) => <Box className="time-box">{children}</Box>;

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}
