import { PlayCircle, StopCircle } from "../../../compatibility/icons";
import { Typography } from "../../../compatibility/components";

import React from "react";
import useTrackMenu from "../../../hooks/useTrackMenu";
import { GridConfig, reconcile } from "../../../config";
import { getGroupById } from "../../../connectors/skyTunesApiConnector";
import useComponentState from "../../../hooks/useComponentState";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import "./GroupList.css";
import Loader from "../../Common/Loader/Loader";
import { useLocation } from "react-router-dom";

export default function useGroupList({ type = "none", id: groupId }) {
  const { state: locationState } = useLocation();
  console.log({ locationState });
  const { state, setState } = useComponentState({
    headerVisible: !0,
    sort: "ID",
    direction: "DESC",
    detailObject: [],
  });
  const {
    playerState,
    showContextDrawer,
    beginTrack,
    playlistKeys,
    AudioEditEvent,
    setPageIndex,
    sitrep,
    screenSize,
    updatePlayerState,
  } = React.useContext(PlayerContext);

  const autoPlay = React.useCallback(() => {
    const { selectedId } = playerState;
    const ls = locationState?.autoPlay;
    if (!(ls && selectedId && state.group?.length)) return;
    const { queue } = selectedId;
    const track = state.group.find((f) => f.ID === ls);
    !!track && beginTrack({ queue, track, groupId, type });
  }, [locationState, beginTrack, groupId, state, playerState, type]);

  const loadList = React.useCallback(
    (page = 1, sort, direction) => {
      const config = GridConfig[type];
      const listSort = sort || config.listSort;
      const sortUp = direction || config.direction;
      setState("groupId", groupId);
      setState("groupType", type);
      sitrep(`Getting ${type}...`);
      getGroupById(type, groupId, page, listSort, sortUp).then((result) => {
        sitrep();
        console.log(
          "LOADING PAGE %s, Type: %s, Time: %d",
          page,
          type,
          new Date().getTime()
        );
        if (!result.related) return;
        setState("page", page);
        setState("direction", sortUp);
        setState("sort", listSort);
        setState("detailObject", result.row[0]);
        setState(
          "group",
          reconcile(result.related, GridConfig.music, playlistKeys)
        );
      });
      return true;
    },
    [type, groupId, setState, sitrep, playlistKeys]
  );

  const { tuneProps } = useTrackMenu({ type, id: groupId });

  React.useEffect(() => {
    const newId = state.groupId !== groupId || state.groupType !== type;
    (newId || !state.loaded) && setState("loaded", loadList());
    const sub = AudioEditEvent.subscribe(() => loadList(state.page));
    setPageIndex(2);
    autoPlay();
    return () => sub.unsubscribe();
  }, [
    state,
    setState,
    type,
    loadList,
    AudioEditEvent,
    groupId,
    setPageIndex,
    autoPlay,
  ]);

  if (!state.detailObject) return <Loader>Loading {type}...</Loader>;

  const {
    Name,
    Title,
    Genre,
    imageLg,
    Thumbnail: photo,
  } = state.detailObject ?? {
    Name: "loading...",
  };

  const basicStyle = screenSize.small ? "header-panel mobile" : "header-panel";

  const contextProps = {
    header: <Typography variant="caption">Sort tracks</Typography>,
    onClick: (j) => {
      const selected = GridConfig[type].columns[j].field;
      const direction = state.direction === "ASC" ? "DESC" : "ASC";
      loadList(1, selected, direction);
    },
    items: GridConfig[type].columns.map((f, i) => (
      <Typography variant={f.field === state.sort ? "h6" : "subtitle1"}>
        {f.label}
      </Typography>
    )),
  };

  const playTune = (queue, track) => {
    beginTrack({ queue, track, groupId, type, photo, label: Name });
  };

  const allPlay = () => {
    if (isPlaying) {
      return updatePlayerState("selectedId", null);
    }
    const queue = state.group;
    const track = queue[0];
    playTune(queue, track);
  };

  const isPlaying = !!playerState?.selectedId?.track;
  const PlayIcon = isPlaying ? StopCircle : PlayCircle;
  const playLabel = isPlaying ? "stop" : "play all";

  const navigateDest = !locationState?.type
    ? `/library/${type}`
    : "/library/" +
      [locationState.type, locationState.id].filter((f) => !!f).join("/");

  return {
    state,
    setState,
    showContextDrawer,
    tuneProps,
    Name,
    Title,
    Genre,
    imageLg,
    basicStyle,
    navigateDest,
    allPlay,
    PlayIcon,
    playLabel,
    isPlaying,
    contextProps,
    locationState,
    screenSize,
    playerState,
    playTune,
  };
}
