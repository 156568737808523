import React from "react";
import { GridConfig, reconcile } from "../config";
import { searchGroupByType } from "../connectors/skyTunesApiConnector";

export default function useSearch(param) {
  const [page, setPage] = React.useState(1);
  const [results, setResults] = React.useState([]);

  const search = React.useCallback(
    async (q, i = 0) => {
      const types = ["music", "artist", "album"];
      if (i < types.length) {
        const type = types[i];
        const config = GridConfig[type];
        const group = await searchGroupByType(type, q, page);
        const items = reconcile(group, config);
        setResults((r) =>
          r.filter((f) => f.type !== type).concat({ items, type })
        );
        return search(q, ++i);
      }
      return true;
    },
    [page]
  );

  React.useEffect(() => {
    !!param && search(param);
  }, [search, param]);

  return { setPage, search, results };
}
