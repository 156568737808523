import { LocalOffer } from "@mui/icons-material";

export const GenreConfig = {
  fieldMap: {
    ID: (p) => p.Genre,
    Thumbnail: (p) => p.albumImage,
    Title: (p) => p.Genre,
    Caption1: (p) => false,
    Caption2: (p) => `${p.TrackCount} tracks`,
  },
  label: "genre",
  logo: LocalOffer,
  icon: <LocalOffer />,
  gridSort: "Genre",
  listSort: "Title",
  sorts: [
    {
      label: "Name",
      field: "Genre",
    },
    {
      label: "Track Count",
      field: "TrackCount",
    },
  ],
  columns: [
    {
      label: "Title",
      field: "Title",
    },
    {
      label: "Artist ",
      field: "artistName",
    },
    {
      label: "Album",
      field: "albumName",
    },
  ],
};
