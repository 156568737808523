import { Apple, Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  Grid,
  Stack,
  Typography,
  styled,
  IconButton,
} from "@mui/material";
import React from "react";
import { getAppleMatches } from "../../../connectors/appleApiConnector";
import {
  updateTable,
  getAlbumorArtistId,
} from "../../../connectors/skyTunesApiConnector";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import Control from "../Control/Control";
import TypeSearchBox from "../TypeSearchBox/TypeSearchBox";
import "./TuneEditForm.css";

const AppleList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  & li {
    padding: 8px 12px;
    border-bottom: solid 1px #e0e0e0;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & .avatar {
      margin-right: 12px;
    }
  }
`;

const TuneEditForm = ({ setOpen, track }) => {
  const {
    playerState = {},
    AudioEditEvent,
    notify,
  } = React.useContext(PlayerContext);
  const { editorState } = playerState;
  const [currentTrack, setCurrentTrack] = React.useState({});
  const [appleRows, setAppleRows] = React.useState(null);

  if (!track) return <em />;
  const { albumImage, Title, FileKey, artistName } = track ?? {
    Title: "who knows??",
  };
  const textFields = [
    {
      content: (
        <Avatar
          onClick={() => window.prompt("Key:", FileKey)}
          src={albumImage}
          alt={Title}
        />
      ),
      xs: 2,
    },
    {
      label: "Title",
      field: "Title",
      xs: 10,
    },
    {
      label: "Track Number",
      field: "trackNumber",
      xs: 6,
    },
    {
      label: "Disc Number",
      field: "discNumber",
      xs: 6,
    },
  ];
  const save = async () => {
    const res = await updateTable(
      "s3Music",
      stripTrack({ ...track, ...currentTrack })
    );
    setCurrentTrack({});
    AudioEditEvent.next(res);
    setOpen && setOpen(false);
  };
  const objectFields = [
    {
      type: "artist",
      field: "artistFk",
      value: "artistName",
      label: "Artist",
    },
    {
      type: "album",
      field: "albumFk",
      value: "albumName",
      label: "Album",
    },
    {
      type: "genre",
      field: "Genre",
      value: "Genre",
      label: "Genre",
    },
  ];
  const update = (n, v) => {
    setCurrentTrack((t) => ({ ...t, [n]: v.ID || v }));
    Object.assign(track, { [n]: v.ID || v });
  };
  const appleLookup = async () => {
    const args = {
      title: Title,
      artist: artistName,
    };
    const res = await getAppleMatches(args);
    console.log({ res });
    if (res) {
      return setAppleRows(res);
    }
    notify("Did not find any results");
  };

  const importApple = async (row) => {
    if (row.albumName) {
      row.albumFk = await getAlbumorArtistId(
        "album",
        row.albumName,
        row.albumImage
      );
    }
    if (row.artistName) {
      row.artistFk = await getAlbumorArtistId(
        "artist",
        row.artistName,
        row.albumImage
      );
    }

    console.log({ row });
    setCurrentTrack((d) => ({ ...d, ...row }));
    Object.assign(track, { ...row });
    setAppleRows(null);
    await save();
    console.log("Done");
  };
  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={editorState.open}
        onClose={() => setOpen(false)}
      >
        <Box className="flex middle wide">
          <Box style={{ maxWidth: 400, margin: 24 }}>
            <Collapse in={!appleRows}>
              <Grid container spacing={2}>
                {textFields.map((f, i) => (
                  <GridItem key={i} object={track} {...f} setValue={update} />
                ))}
                {objectFields.map((f, i) => (
                  <Grid item xs={12} key={i}>
                    <TypeSearchBox
                      {...f}
                      onSet={update}
                      value={{
                        Name: track[f.value],
                        ID: track[f.field],
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={appleLookup}
                    color="success"
                    fullWidth
                  >
                    <Apple />
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button variant="outlined" fullWidth>
                    cancel
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button variant="contained" onClick={save} fullWidth>
                    save
                  </Button>
                </Grid>
              </Grid>
            </Collapse>

            <Collapse in={appleRows}>
              <Control.EasyList>
                <li className="easy-header flex center">
                  Suggestions from Apple.com:
                  <IconButton
                    className="auto"
                    onClick={() => setAppleRows(null)}
                  >
                    <Close />
                  </IconButton>
                </li>
                {appleRows?.map((row, i) => (
                  <li key={i} onClick={() => importApple(row)}>
                    <Avatar
                      variant="rounded"
                      className="avatar"
                      src={row.albumImage}
                      alt={row.Title}
                    />
                    <Stack>
                      <Typography variant="subtitle1">{row.Title}</Typography>
                      <Typography variant="subtitle2">
                        {row.artistName}
                      </Typography>
                      <Typography variant="subtitle2">
                        {row.albumName}
                      </Typography>
                    </Stack>
                  </li>
                ))}
              </Control.EasyList>
            </Collapse>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

function GridItem({ content, xs, label, field, object, setValue }) {
  return (
    <Grid xs={xs} item className="flex center middle">
      {!!content ? (
        content
      ) : (
        <Control.TextBox
          fullWidth
          label={label}
          value={object[field]}
          setValue={(v) => setValue(field, v)}
          onChange
        />
      )}
    </Grid>
  );
}

/**
 
Genre,Title,albumFk,artistFk,discNumber,trackNumber 


 */
TuneEditForm.defaultProps = {};
export default TuneEditForm;

const stripTrack = (track) => {
  const {
    Genre,
    Title,
    albumFk,
    artistFk,
    discNumber,
    trackNumber,
    ID,
    albumImage,
    trackTime,
  } = track;
  return {
    Genre,
    Title,
    albumFk,
    artistFk,
    discNumber,
    trackNumber,
    ID,
    trackTime,
    albumImage,
  };
};
