import { CLOUD_FRONT_URL } from "../constants";

export function playerUrl(FileKey) {
  const audioURL = `${CLOUD_FRONT_URL}${FileKey}`
    .replace("#", "%23")
    .replace(/\+/g, "%2B");
  return audioURL;
}

export const createKey = (name) => name.replace(/[\s\&\-]/g, "").toLowerCase();

const ten = (f) => (Math.abs(f) < 10 ? `0${Math.abs(f)}` : Math.abs(f));

export const mmss = (time) => {
  if (isNaN(time)) return "infinite";
  const ab = time > 0 ? "" : "-";
  const hh = ten(Math.floor(time / 3600));
  const mm = ten(Math.floor(time / 60) % 60);
  const ss = ten((time % 60).toFixed(2));
  const pr = hh > 0 ? `${hh}:` : "";
  return `${ab}${pr}${mm}:${ss}`;
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    skeletonSize: (fraction, offset) => width * fraction - offset,
  };
}

export const shuffle = (unshuffled) =>
  unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const css = (o) =>
  Object.keys(o)
    .filter((f) => !!o[f])
    .join(" ");

export const collate = (length, pageSize, page) => {
  const startPage = (page - 1) * pageSize;
  return {
    startPage,
    page,
    pageSize,
    length,
  };
};

export const serialPromise = async ({ objects, action, stats, ...props }) => {
  const stats$ = {
    count: stats?.count || objects.length,
    start: stats?.start || new Date().getTime(),
  };
  if (!objects.length) return true;
  const { count, start } = stats$;
  const object = objects.shift();
  const done = stats$.count - objects.length;
  const secs = (new Date().getTime() - start) / 1000;
  const rate = (secs / done).toFixed(3);
  const left = rate * objects.length;
  await action(object, props, objects.length);
  console.log({
    status: `${objects.length} remaining of ${count}. Rate: ${rate}. Remaining: ${left}`,
  });
  await serialPromise({ objects, action, stats: stats$, ...props });
};
