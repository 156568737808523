import { Badge, Box, Chip, IconButton, Typography } from "@mui/material";
import {
  Close,
  CopyAll,
  Edit,
  ExpandMore,
  GridView,
  Save,
  SortByAlpha,
  ViewList,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import {
  getGroupByType,
  saveTrack,
} from "../../../connectors/skyTunesApiConnector";
import { css } from "../../../util/common";
import "./GroupGrid.css";
import useComponentState from "../../../hooks/useComponentState";
import TuneList from "../../Common/TuneList/TuneList";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import ListTitleBar from "../../Common/ListTitleBar/ListTitleBar";
import { GridConfig, reconcile } from "../../../config";
import { collate, serialPromise } from "../../../util/common";
import PaginationBar from "../../Common/PaginationBar/PaginationBar";
import Control from "../../Common/Control/Control";
import useTrackMenu from "../../../hooks/useTrackMenu";
import Loader from "../../Common/Loader/Loader";
import useScreenSize from "../../../hooks/useScreenSize";
import TypeMenu from "../../Common/TypeMenu/TypeMenu";

const types = ["artist", "playlist", "genre", "album"];

const GroupGrid = ({ type = "music" }) => {
  const { state: locationState } = useLocation();
  // console.log({ locationState });
  const { state, setState } = useComponentState({
    headerVisible: true,
    page: 1,
    sort: "ID",
    direction: "DESC",
    group: [],
  });
  const {
    playerState,
    showContextDrawer,
    beginTrack,
    playlistKeys,
    AudioEditEvent,
    sitrep,
    setGridView,
    screenSize,
    setPageIndex,
    notify,
  } = React.useContext(PlayerContext);

  const loadGroup = React.useCallback(
    (page = 1, sort, direction) => {
      const config = GridConfig[type];
      const gridSort = sort || config.gridSort;
      const sortUp = direction || config.direction;
      setState("busy", !0);
      setState("groupType", type);
      sitrep(`Getting page ${page}...`);
      getGroupByType(type, page, gridSort, sortUp).then((result) => {
        sitrep();
        console.log("LOADING PAGE", page, { type, result });
        setState("busy", !1);
        setState("page", page);
        setState("direction", sortUp);
        setState("sort", gridSort);
        setState("count", result.count);
        setState("group", reconcile(result, config, playlistKeys));
      });
      return true;
    },
    [type, setState, playlistKeys, sitrep]
  );

  const onComponentLoaded = React.useCallback(async () => {
    (state.groupType !== type || !state.loaded) &&
      setState("loaded", loadGroup(locationState?.page));
  }, [state, type, setState, loadGroup, locationState]);

  // load group info when component loads
  React.useEffect(() => {
    onComponentLoaded();
    const sub = AudioEditEvent.subscribe(() => loadGroup(state.page));
    setPageIndex(2);
    return () => sub.unsubscribe();
  }, [onComponentLoaded, loadGroup, AudioEditEvent, state, setPageIndex]);

  const navigate = useNavigate();
  const headerStyle = state.headerVisible ? "header-panel" : "header-panel off";

  const { tuneProps } = useTrackMenu({ type });

  const contextProps = {
    header: <Typography variant="caption">Sort {type}s</Typography>,
    onClick: (j) => {
      const selected = GridConfig[type].sorts[j].field;
      const direction = state.direction === "ASC" ? "DESC" : "ASC";
      loadGroup(1, selected, direction);
    },
    items: GridConfig[type].sorts.map((f, i) => (
      <Typography key={i} variant={f.field === state.sort ? "h6" : "subtitle1"}>
        {f.label}
      </Typography>
    )),
  };

  const emptyCategory = !type || type === "music";
  const collated = collate(state.count, 100, state.page);

  const { selectedId, viewGrid } = playerState ?? { selectedId: {} };
  const { currentType, currentId } = selectedId ?? {};
  const selectedArgs = { currentType, currentId };
  const ViewIcon = viewGrid ? ViewList : GridView;

  /**
   * inline edit functions
   */
  const ActionIcon = state.copyMode ? CopyAll : Edit;
  const clearTargets = async () => {
    const { albumFk, artistFk, albumImage } = state.clipBoard;
    const updatedTargets = state.targets.map((t) => ({
      ...t,
      albumFk,
      artistFk,
      albumImage,
    }));
    sitrep("Updating files");
    await serialPromise({
      objects: updatedTargets,
      action: async (f) => {
        console.log("Saving '%s'", f.Title);
        return await saveTrack(f);
      },
    });
    AudioEditEvent.next();
    notify("Files updated");
    setState("clipBoard", null);
    setState("targets", null);
  };

  const copyBadge = (
    <Badge badgeContent={state.targets?.length}>
      <IconButton onClick={() => clearTargets()}>
        <Save />
      </IconButton>
    </Badge>
  );

  const headerTools = [
    <Control.SearchPanel
      autoFocus
      onExpose={(v) => setState("hideTitle", v)}
      size="small"
      value={state.param}
      label="Search"
      setValue={(v) => setState("param", v)}
      onChange={() => navigate(`/search/${state.param}`)}
    />,
    !!state.targets?.length ? (
      copyBadge
    ) : (
      <IconButton
        onClick={() => {
          setState("copyMode", !state.copyMode);
          sitrep(!state.copyMode ? "Select a track to copy" : null);
        }}
      >
        <ActionIcon />
      </IconButton>
    ),
  ];

  const playTune = (groupId, queue, track) => {
    beginTrack({ queue, track, groupId, type });
  };

  const { gridStyle } = useScreenSize();
  const loader = type === "music" ? "collection" : type + "s";
  if (!state.group?.length) return <Loader>Loading {loader}...</Loader>;
  const openMenu = (tune) => showContextDrawer(tuneProps(tune));
  return (
    <div className="GroupGrid level-4">
      <Box className={`${headerStyle}`}>
        {/* header line 1 */}
        <ListTitleBar
          hideTitle={state.hideTitle}
          tools={headerTools}
          variant={screenSize.small ? "body2" : "h6"}
        >
          {state.count}{" "}
          {emptyCategory ? " tracks in your Library  " : `${type}s`}
        </ListTitleBar>

        {/* header line 2 */}
        <Box className="flex">
          <TypeMenu type={type} small={screenSize.small} />
          {/* {!emptyCategory && (
            <>
              <Link
                to="/library"
                state={{ ...locationState, page: state.page }}
              >
                <IconButton style={{ marginLeft: 12 }}>
                  <Close />
                </IconButton>
              </Link>
              <Chip
                label={screenSize.small ? "" : `${type}s`}
                color="success"
                icon={GridConfig[type].icon}
                variant="filled"
                className={
                  screenSize.small ? "header-chip tiny" : "header-chip"
                }
                size={screenSize.small ? "small" : "medium"}
              />
              <Control.SimpleMenu
                options={types.map((t) => (
                  <Box className="flex center" key={t}>
                    {GridConfig[t].icon}{" "}
                    <Box
                      className={css({ caps: 1, bold: t === type })}
                      style={{ marginLeft: 12 }}
                    >
                      {t}
                    </Box>
                  </Box>
                ))}
                icon={<ExpandMore />}
                onClick={(i) =>
                  navigate(`/library/${types[i]}`, {
                    ...locationState,
                    page: state.page,
                  })
                }
              />
            </>
          )}
          {!!emptyCategory &&
            types.map((typeName, i) => (
              <Link
                className="link"
                key={i}
                to={`/library/${typeName}`}
                state={{ ...locationState, page: state.page }}
              >
                {" "}
                <Chip
                  label={screenSize.small ? "" : <>{typeName}</>}
                  size={screenSize.small ? "small" : "medium"}
                  icon={GridConfig[typeName].icon}
                  color="primary"
                  variant="outlined"
                  className={
                    screenSize.small ? "header-chip tiny" : "header-chip"
                  }
                />
              </Link>
            ))} */}
          <Box className="auto header-button">
            {!!collated.length && (
              <PaginationBar
                {...collated}
                hideLabel={screenSize.small}
                label={type === "music" ? "track" : type}
                click={(i) => {
                  loadGroup(state.page + i, state.sort, state.direction);
                }}
              />
            )}
          </Box>
          <Box>
            <IconButton
              className="header-button"
              onClick={() => showContextDrawer(contextProps)}
            >
              <SortByAlpha />
            </IconButton>
            {!(screenSize.small && emptyCategory) && (
              <IconButton
                className="header-button"
                onClick={() => setGridView(!viewGrid)}
              >
                <ViewIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>

      {!!state.group && (
        <div
          className="level-5"
          style={{
            maxHeight: "calc(100vh - 120px)",
            overflow: "auto",
            ...gridStyle,
          }}
        >
          <TuneList
            state={{ type, page: state.page }}
            viewGrid={viewGrid}
            gridType={type}
            menuClick={type === "music" ? openMenu : null}
            onClick={(id, queue, track) => {
              const targets = state.targets ?? [];
              if (state.clipBoard) {
                const is = targets.indexOf(track) > -1;
                setState(
                  "targets",
                  is
                    ? targets.filter((t) => t.ID !== track.ID)
                    : targets.concat([track])
                );
                notify(
                  `${track.Title} ${is ? "removed" : "added"}`,
                  is ? "error" : "success"
                );
                setState("copyMode", false);
                return;
              }
              if (state.copyMode) {
                setState("clipBoard", track);
                setState("copyMode", false);
                sitrep(`Select tracks to paste "${track.Title}" onto`);
                return;
              }
              if (type !== "music") {
                return navigate(`/library/${type}/${id}`);
              }
              playTune(id, queue, track);
            }}
            selectedItems={state.targets}
            selectedId={playerState?.selectedId?.track?.ID}
            group={state.group}
            {...selectedArgs}
          />
        </div>
      )}
    </div>
  );
};

GroupGrid.defaultProps = {};
export default GroupGrid;
