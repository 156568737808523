import {
  Album,
  MusicNote,
  MusicNoteTwoTone,
  People,
  PlayCircle,
  QueueMusic,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Grid,
  Box,
  Avatar,
  Paper,
  Divider,
  IconButton,
  LinearProgress,
} from "@mui/material";
import React from "react";
import {
  decorateTracks,
  getDashboard,
  getGroupById,
  getGroupByType,
  serialSearch,
} from "../../../connectors/skyTunesApiConnector";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import usePlaylist from "../../../hooks/usePlaylist";
import useScreenSize from "../../../hooks/useScreenSize";
import { createKey, css, shuffle } from "../../../util/common";
import Control from "../../Common/Control/Control";
import ListTitleBar from "../../Common/ListTitleBar/ListTitleBar";
import TuneCard from "../../Common/TuneCard/TuneCard";
import Workspace from "../Workspace/Workspace";
import "./HomeGrid.css";
import Loader from "../../Common/Loader/Loader";
import useScrollCouple from "../../Common/ScrollCouple/useScrollCouple";
import ScrollCouple from "../../Common/ScrollCouple/ScrollCouple";

const HomeGrid = () => {
  const { playlists } = usePlaylist();
  const [searchParam, setSearchParam] = React.useState("");
  const [hideTitle, setHideTitle] = React.useState(false);
  const { screenSize, beginTrack, playerState, setPageIndex, sitrep } =
    React.useContext(PlayerContext);
  const [dashData, setDashData] = React.useState({});
  const [tuneData, setTuneData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const { outerStyle, innerStyle, scrollerRef } = useScrollCouple(60, 360);

  const getDash = React.useCallback(async () => {
    const cache = JSON.parse(sessionStorage.getItem("dashboard") ?? "{}");
    const data = cache.data ?? (await getDashboard());

    const artists = shuffle(data.filter((d) => d.Type === "artist")).slice(
      0,
      screenSize.small ? 4 : 8
    );
    const albums = shuffle(data.filter((d) => d.Type === "album")).slice(
      0,
      screenSize.small ? 4 : 8
    );
    setDashData((d) => ({
      ...d,
      artists,
      albums,
    })); //decorateTracks

    const music = cache.music ?? (await getGroupByType("music"));
    sessionStorage.setItem("dashboard", JSON.stringify({ music, data }));
    const recs = shuffle(
      music.records.filter((f) => !!f.albumImage && !!f.artistFk)
    ).slice(0, 3);
    const pruned = await decorateTracks(recs);

    setTuneData(pruned);
  }, [screenSize]);

  const { gridStyle } = useScreenSize();

  React.useEffect(() => {
    !loaded && getDash();
    !!playlists.length &&
      !dashData.offers &&
      setDashData((d) => ({
        ...d,
        offers: shuffle(playlists.filter((q) => !!q.image)).slice(0, 6),
      }));
    setLoaded(true);
    setPageIndex(0);
  }, [getDash, loaded, playlists, setPageIndex, screenSize, dashData]);

  // if (!playlists.length) return <Loader>Loading playlists</Loader>;
  // if (!dashData.artists) return <Loader>Hang on one moment...</Loader>;
  const { artists, albums, offers } = dashData ?? {};

  const timeIndex = Math.floor(new Date().getHours() / 6);
  const greetings = ["morning", "morning", "afternoon", "evening"];
  const getItem = async (groupId, type) => {
    const sortBy =
      type === "artist"
        ? "albumFk, discNumber, trackNumber"
        : "discNumber, trackNumber";
    sitrep(`Loading  ${type}...`);
    const item = await getGroupById(type, groupId, 1, sortBy, "ASC");
    sitrep();
    console.log({ item });
    const queue = item.related.records;
    if (!queue) return alert("Could not retrieve " + type + "/" + groupId);
    beginTrack({ queue, track: queue[0], type, groupId });
  };
  const playItem = (track) => {
    beginTrack({ queue: [track], track });
  };

  const getList = async (title) => {
    const groupId = createKey(title);
    sitrep(`Loading playlist ${title}...`);
    const item = await getGroupById(
      "playlist",
      groupId,
      1,
      "trackNumber",
      "DESC"
    );
    sitrep();
    const queue = item.related.records;
    beginTrack({ queue, track: queue[0], type: "playlist", groupId });
  };
  const headerTools = [
    <Control.SearchPanel
      autoFocus
      onExpose={(b) => setHideTitle(b)}
      size="small"
      beginTrack={beginTrack}
      value={searchParam}
      label="Search"
      setValue={(v) => setSearchParam(v)}
      onChange={async () => {
        // navigate(`/search/${searchParam}`);
        const res = await serialSearch(searchParam);
        console.log(res);
      }}
    />,
  ];
  const { selectedId } = playerState ?? { selectedId: {} };
  const { currentType, currentId } = selectedId ?? {};
  const selectedArgs = { currentType, currentId };

  return (
    <div className="HomeGrid" style={outerStyle}>
      <Workspace scroll selected={0}>
        <Box className="home-grid-scroll" style={gridStyle}>
          <Box>
            <ListTitleBar
              hideTitle={hideTitle}
              variant={screenSize.small ? "body1" : "h6"}
              Icon={MusicNoteTwoTone}
              image="/assets/icon-72x72.png"
              tools={headerTools}
            >
              <>Good {greetings[timeIndex]}, welcome to SkyTunes</>
              {/* {screenSize.small ? (
                <> Good {greetings[timeIndex]}</>
              ) : (
                <>Good {greetings[timeIndex]}, welcome to SkyTunes</>
              )} */}
            </ListTitleBar>
          </Box>
          {!!artists?.length && (
            <ScrollCouple
              small={160}
              large={300}
              className={screenSize.small ? "mobile" : ""}
            >
              <Carousel
                onClick={getItem}
                artists={artists.filter((f) => !!f.imageLg)}
              />
            </ScrollCouple>
          )}

          <div style={innerStyle} ref={scrollerRef}>
            <TunePanel
              onClick={playItem}
              items={tuneData}
              small={screenSize.small}
            />

            <PlayPanel
              small={screenSize.small}
              items={offers}
              onClick={getList}
              {...selectedArgs}
            />

            <ListPanel
              Icon={People}
              listType="artist"
              items={artists}
              {...selectedArgs}
              label="Top Artists"
              onClick={getItem}
            />
            <ListPanel
              Icon={Album}
              listType="album"
              items={albums}
              {...selectedArgs}
              label="Top Albums"
              onClick={getItem}
            />
          </div>
        </Box>
      </Workspace>
    </div>
  );
};

const PlayPanel = ({
  items,
  onClick,
  listType,
  currentType,
  currentId,
  small,
}) => {
  if (!items) return <i />;
  const cardStyle = small ? "cropped small" : "cropped";

  const avatarStyle = small
    ? { marginRight: 6 }
    : { marginRight: 12, minHeight: 72, minWidth: 72 };
  return (
    <>
      <Stack className="stack">
        <Divider textAlign="left" className="divider">
          <Box className="flex center">
            <QueueMusic className="button-icon" /> Playlists
          </Box>
        </Divider>
        <Grid spacing={2} container>
          {items.map((item, i) => {
            const selected =
              "playlist" === currentType &&
              currentId?.toString() === createKey(item.Title);
            const args = {
              item,
              onClick,
              avatarStyle,
              cardStyle,
              selected,
            };
            return (
              <Grid key={i} item xs={6} md={4}>
                <PhotoCard {...args} />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </>
  );
};

const TunePanel = ({ items, onClick, currentType, currentId, small }) => {
  if (!items?.length) return <LinearProgress />;
  const cardStyle = small ? "cropped small" : "cropped";

  const avatarStyle = { marginRight: 12, minHeight: 72, minWidth: 72 };

  const shown = items.slice(0, small ? 1 : 3);
  return (
    <Box className="stack">
      <Divider textAlign="left" className="divider">
        <Box className="flex center">
          <MusicNote className="button-icon" /> New in your Library
        </Box>
      </Divider>
      <Grid container spacing={2}>
        {shown.map((s, i) => {
          const args = {
            src: s.albumImage,
            title: s.Title,
            avatarStyle,
            cardStyle,
            caption: <> {s.albumName}</>,
          };
          return (
            <Grid xs={small ? 12 : 4} item key={i}>
              <Box className="flex center" mb={1} onClick={() => onClick(s)}>
                <Avatar
                  className="header-button"
                  src={s.Thumbnail}
                  alt={s.artistName}
                />
                <Stack>
                  <Typography variant="button">recenty added track</Typography>
                  <Typography variant="body2" className="no-wrap">
                    {s.artistName}
                  </Typography>
                </Stack>
              </Box>
              <GenericCard {...args} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const PhotoCard = ({ item, selected, onClick, avatarStyle, cardStyle }) => {
  const args = {
    src: item.image,
    title: item.Title,
    caption: (
      <>
        {" "}
        {item.trackCount} tracks {selected && <Control.Eq />}
      </>
    ),
    elevation: selected ? 5 : 2,
    onClick,
    avatarStyle,
    cardStyle,
  };
  return <GenericCard {...args} />;
};

const GenericCard = ({
  src,
  title,
  caption,
  elevation,
  onClick,
  avatarStyle,
  cardStyle,
}) => {
  return (
    <>
      <Paper
        elevation={elevation}
        classes={{ root: cardStyle, paper: cardStyle }}
        className="flex"
        onClick={() => onClick(title)}
      >
        <Avatar
          variant="rounded"
          size="large"
          src={src}
          alt={title}
          style={avatarStyle}
        />

        <Stack className="flex middle home-play-stack">
          <Typography variant="body1" className="no-wrap">
            {title}
          </Typography>
          <Typography variant="caption" className="flex wide no-wrap">
            {caption}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};

const ListPanel = ({
  items,
  label,
  onClick,
  listType,
  currentType,
  currentId,
  Icon,
}) => {
  if (!items) return <i />;
  return (
    <>
      <Stack className="stack">
        <Divider textAlign="left" className="divider">
          <Box className="flex center">
            <Icon className="button-icon" /> {label}
          </Box>
        </Divider>

        <Grid spacing={2} container>
          {items.map((item, i) => (
            <Grid key={i} item xs={6} md={4} lg={3}>
              <Box onClick={() => onClick && onClick(item.ID, item.Type)}>
                <TuneCard
                  selected={
                    listType === currentType &&
                    currentId?.toString() === item.ID.toString()
                  }
                  Title={item.Name}
                  Thumbnail={item.Thumbnail}
                  Caption1={item.Caption}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};

HomeGrid.defaultProps = {};
export default HomeGrid;

const enactSlide = (stateSetters, setCurrent) => {
  let timer1, timer2;
  const next = () => {
    setCurrent();
    stateSetters.map((f) => f(false));
    !!timer1 && clearTimeout(timer1);
    timer2 = setTimeout(() => {
      enactSlide(stateSetters, setCurrent);
    }, 5999);
  };
  stateSetters.map((f) => f(true));
  !!timer2 && clearTimeout(timer2);
  timer1 = setTimeout(next, 499);
};

const Carousel = ({ artists, onClick }) => {
  const num = React.useRef(0);
  const [running, setRunning] = React.useState(false);
  const [runnable, setRunnable] = React.useState(false);
  const [timer, setTimer] = React.useState(null);
  const showPic = artists[num.current % artists.length];
  const hidePic = artists[(num.current + 1) % artists.length];

  const setCurrent = () => (num.current = num.current + 1);

  React.useEffect(() => {
    !timer &&
      setTimer(
        setTimeout(
          () => enactSlide([setRunnable, setRunning], setCurrent),
          9999
        )
      );
  }, [timer]);

  return (
    <Box className={css({ running, runnable })}>
      <Box className="text flex center">
        <Avatar
          className="header-button"
          variant="rounded"
          src={showPic.Thumbnail}
          alt={showPic.Name}
        />
        <Stack>
          <Typography variant="h6">{showPic.Name}</Typography>
          <Typography variant="body2">
            {showPic.Caption} in your library
          </Typography>
        </Stack>
        <IconButton onClick={() => onClick(showPic.ID, "artist")}>
          <PlayCircle style={{ width: 44, height: 44, color: "white" }} />
        </IconButton>
      </Box>

      <img
        src={showPic.imageLg}
        alt="{Title}"
        className="carousel-show scroll-head"
      />
      <img
        src={hidePic.imageLg}
        alt="{Title}"
        className="carousel-hide scroll-head"
      />
    </Box>
  );
};
