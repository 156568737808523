import React from "react";
import {
  getGroupByType,
  savePlaylist,
} from "../connectors/skyTunesApiConnector";
export default function usePlaylist() {
  const [playlistKeys, setKeys] = React.useState([]);
  const [playlistTitles, setPlaylistTitles] = React.useState([]);
  const [playlists, setPlaylists] = React.useState([]);
  const getKeys = React.useCallback(async () => {
    const serverItems = await getGroupByType("playlist");
    console.log({ serverItems });
    const collater = ((o, q) => {
      serverItems.records.map((f) => (o = o.concat(f.related)));
      serverItems.records.map((f) => q.push(f.Title));
      return {
        keys: o,
        names: q,
      };
    })([], []);

    setKeys(collater.keys);
    setPlaylistTitles(collater.names);
    setPlaylists(serverItems.records);
    return collater.names;
  }, []);

  const addToList = async (title, tune) => {
    const list = playlists.find((p) => p.Title === title);
    const is = list.related.indexOf(tune.FileKey) > -1;
    list.related = is
      ? list.related.filter((f) => f !== track.FileKey)
      : list.related.concat([tune.FileKey]);
    const { ID, track, TrackCount, ...rest } = list;
    const response = await savePlaylist(rest);
    await getKeys();
    return response;
  };

  const matchTrack = (track) => {
    return playlists
      .filter((f) => f.related.indexOf(track.FileKey) > -1)
      .map((f) => f.Title);
  };

  React.useEffect(() => {
    console.log("GETTING KEYS");
    getKeys();
  }, [getKeys]);
  return {
    playlistKeys,
    getKeys,
    playlistTitles,
    matchTrack,
    addToList,
    playlists,
  };
}
