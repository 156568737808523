import { Alert, Drawer } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import "./SitRepDrawer.css";

const SitRepDrawer = () => {
  const { playerState = {}, setSitRepState } = React.useContext(PlayerContext);
  const { sitRepState } = playerState;
  return (
    <React.Fragment>
      <Drawer
        anchor="bottom"
        open={!!sitRepState.open}
        onClose={() => setSitRepState({ open: false })}
      >
        {" "}
        <Alert severity="info"> {sitRepState.message}</Alert>
      </Drawer>
    </React.Fragment>
  );
};

SitRepDrawer.defaultProps = {};
export default SitRepDrawer;
