import "./App.css";
import React from "react";
import GroupGrid from "./components/Layout/GroupGrid/GroupGrid";
import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import GroupList from "./components/Layout/GroupList/GroupList";
import { PlayerContext } from "./hooks/usePlayerContext";
import AudioPlayer from "./components/Common/AudioPlayer/AudioPlayer";
import SearchGrid from "./components/Layout/SearchGrid/SearchGrid";
import Control from "./components/Common/Control/Control";
import SocketDrawer from "./components/Common/SocketDrawer/SocketDrawer";
import HomeGrid from "./components/Layout/HomeGrid/HomeGrid";
import Workspace from "./components/Layout/Workspace/Workspace";
import AppNotifier from "./components/Common/AppNotifier/AppNotifier";
import TuneEditForm from "./components/Common/TuneEditForm/TuneEditForm";
import MiniDrawer from "./components/Common/MiniDrawer/MiniDrawer";
import SitRepDrawer from "./components/Common/SitRepDrawer/SitRepDrawer";
import useSkyTunes from "./hooks/useSkyTunes";
import SearchBar from "./components/Common/SearchBar/SearchBar";
import AuthScreen from "./components/Auth/AuthScreen/AuthScreen";
import QueueDrawer from "./components/Common/Drawer/QueueDrawer/QueueDrawer";

const GenericPage = ({ Component, selected, ...props }) => {
  const params = useParams();
  return (
    <Workspace selected={selected}>
      <Component {...params} {...props} />
    </Workspace>
  );
};

function App() {
  const skyTunes = useSkyTunes();
  const { playerState, screenSize, beginTrack } = skyTunes;
  return (
    <PlayerContext.Provider value={{ ...skyTunes }}>
      {playerState.ready && <AudioPlayer />}
      <Box className="App level-1">
        <BrowserRouter>
          <Routes>
            <Route
              path="/library/:type/:id"
              element={<GenericPage Component={GroupList} selected={2} />}
            />
            <Route
              path="/library/:type"
              element={<GenericPage Component={GroupGrid} selected={2} />}
            />
            <Route
              path="/library"
              element={<GenericPage Component={GroupGrid} selected={2} />}
            />

            <Route
              path="/search/:param"
              element={<GenericPage Component={SearchGrid} selected={1} />}
            />
            <Route
              path="/search/"
              element={<GenericPage Component={SearchGrid} selected={1} />}
            />
            <Route path="/signin" element={<AuthScreen />} />
            <Route path="/" element={<HomeGrid />} />
          </Routes>
          <MiniDrawer />
          <SearchBar beginTrack={beginTrack} />
        </BrowserRouter>
        <QueueDrawer />
      </Box>
      <SitRepDrawer />
      <TuneEditForm {...playerState.editorState} />
      <Control.ContextDrawer {...screenSize} />
      <SocketDrawer onComplete={() => alert("Complete!")} />
      <AppNotifier {...playerState.notifierState} />
    </PlayerContext.Provider>
  );
}

export default App;
