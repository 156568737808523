import { PlaylistConfig } from "./PlaylistConfig";
import { AlbumConfig } from "./AlbumConfig";
import { MusicConfig } from "./MusicConfig";
import { ArtistConfig } from "./ArtistConfig";
import { GenreConfig } from "./GenreConfig";

export const GridConfig = {
  artist: ArtistConfig,
  music: MusicConfig,
  album: AlbumConfig,
  genre: GenreConfig,
  playlist: PlaylistConfig,
};

export const reconcile = (items = {}, config, keys) => {
  const { fieldMap } = config;
  const { records } = items;
  const reconciled = records?.map((item) => {
    return ((q) => {
      Object.keys(fieldMap).map((fieldName) => {
        q[fieldName] = fieldMap[fieldName](item);
        return fieldName;
      });
      const favorite =
        !!item.FileKey && keys && keys.indexOf(item.FileKey) > -1;
      return { ...q, ...item, favorite };
    })({});
  });
  return reconciled;
};

export { PlaylistConfig, AlbumConfig, MusicConfig, ArtistConfig, GenreConfig };
