import { MusicNote } from "@mui/icons-material";
import { Box, Chip, Divider } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GridConfig, reconcile } from "../../../config";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import usePlayerHistory from "../../../hooks/usePlayerHistory";
import useSearch from "../../../hooks/useSearch";
import Control from "../../Common/Control/Control";
import ListTitleBar from "../../Common/ListTitleBar/ListTitleBar";
import TuneList from "../../Common/TuneList/TuneList";
import "./SearchGrid.css";

const SearchGrid = ({ param }) => {
  const { state: locationState } = useLocation();
  const { memory } = usePlayerHistory();
  console.log({ locationState });
  const [index, setIndex] = React.useState(0);
  const [searchParam, setSearchParam] = React.useState(param);
  const { results, search } = useSearch(param);
  const { playerState, beginTrack, setPageIndex, screenSize } =
    React.useContext(PlayerContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);
  if (!results) return <em>Loading...</em>;
  const types = results.map((r) => r.type);
  const type = index > 0 ? types[index] : "song";
  const active = results[index];
  const group = active?.items;
  const config = GridConfig[types[index]];
  const Logo = config?.logo || MusicNote;
  const headerTools = [
    <Control.SearchPanel
      textOnly
      exposed
      autoFocus
      size="small"
      value={searchParam}
      label="Search"
      setValue={(v) => setSearchParam(v)}
      onChange={() => search(searchParam)}
    />,
  ];
  const items = reconcile({ records: memory }, GridConfig.music);
  const decoratedIds = Array.from(new Set(items.map((f) => f.groupId))).map(
    (k) => items.find((z) => z.groupId === k)
  );
  const decoratedItems = decoratedIds
    .filter((f) => !!f.type)
    .map((o) => ({
      ID: o.ID,
      Title: o.label,
      Thumbnail: o.photo,
      Caption1: "go to " + o.type + "...",
      Href1: `/library/${o.type}/${o.groupId}`,
    }))
    .slice(0, 12);
  const headerTitle =
    !screenSize.small && !!searchParam
      ? `${type}s matching "${searchParam}"`
      : "Search";
  const style = {
    "--tune-grid-columns": "144px 0 24px",
    "--factor": "16%",
    "--thumbnail-image-height": "140px",
  };
  return (
    <div className="SearchGrid" style={style}>
      <Box className="header-panel">
        <ListTitleBar Icon={Logo} tools={headerTools}>
          {headerTitle}
        </ListTitleBar>
        {/* header line 2 */}
        <Box className="flex">
          {!!types &&
            types.map((typeName, i) =>
              !results[i].items.length ? (
                <i />
              ) : (
                <Chip
                  onClick={() => setIndex(i)}
                  key={typeName}
                  label={`${typeName} (${results[i].items.length})`}
                  icon={GridConfig[typeName].icon}
                  color={index === i ? "success" : "primary"}
                  variant={index === i ? "filled" : "outlined"}
                  className="header-chip"
                />
              )
            )}
        </Box>
      </Box>
      {!group && (
        <>
          <Divider style={{ minWidth: "100%" }} textAlign="left">
            Recent Tracks
          </Divider>
          <TuneList
            onClick={(groupId, queue, track) =>
              beginTrack({ groupId, queue: [track], track, type: track.type })
            }
            viewGrid
            group={items.slice(0, 12)}
          />
        </>
      )}
      {!group && !!decoratedItems && (
        <>
          <Divider style={{ minWidth: "100%" }} textAlign="left">
            Jump Back In
          </Divider>
          <TuneList
            onClick={(a, b, c) => {
              navigate(c.Href1, { state: { autoPlay: a } });
            }}
            viewGrid
            group={decoratedItems}
          />
        </>
      )}
      {!!group && types[index] && (
        <TuneList
          {...screenSize}
          state={{ type: "search", param }}
          onClick={(groupId, queue, track) => {
            if (types[index] !== "music") {
              return navigate(`/library/${types[index]}/${groupId}`);
            }
            beginTrack({ groupId, queue, track, type: types[index] });
          }}
          selectedId={playerState?.selectedId?.track?.ID}
          group={group}
        />
      )}
    </div>
  );
};

SearchGrid.defaultProps = {};
export default SearchGrid;
