import React from "react";
import { AudioRequestEvent } from "../components/Common/AudioPlayer/AudioPlayer";
import { PlayerContext } from "./usePlayerContext";

export default function useAndroidConnect() {
  const { nextTrack } = React.useContext(PlayerContext);

  const androidNotify = ({ Title, artistName, albumName, albumImage }) => {
    if ("mediaSession" in navigator) {
      const code = ` navigator.mediaSession.metadata = new MediaMetadata({
        title: '${Title}',
        artist: '${artistName}',
        album: '${albumName}',
        artwork: [
          { src: '${albumImage}',   sizes: '96x96',   type: 'image/jpeg' },
          { src: '${albumImage}', sizes: '512x512', type: 'image/jpeg' },
        ]
      });`;
      try {
        // eslint-disable-next-line no-eval
        eval(code);
      } catch (e) {
        console.log(e);
      }
      navigator.mediaSession.setActionHandler("play", () =>
        AudioRequestEvent.next({ type: "play" })
      );
      navigator.mediaSession.setActionHandler("pause", () =>
        AudioRequestEvent.next({ type: "play" })
      );
      navigator.mediaSession.setActionHandler("previoustrack", function () {
        nextTrack(-1);
      });
      navigator.mediaSession.setActionHandler("nexttrack", function () {
        nextTrack(1);
      });
    }
  };
  return { androidNotify };
}
