import { Home, LibraryBooks, Search } from "@mui/icons-material";
import { styled, Box } from "@mui/material";
import React from "react";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import { AudioPlayerEvent } from "../AudioPlayer/AudioPlayer";
import Control from "../Control/Control";
import MusicCard from "../MusicCard/MusicCard";
import "./SideNavigation.css";

const LinkList = styled("ul")`
  list-style: none;
  padding: 0;
  margin: 12px 12px;
  & li {
    padding: 6px 8px;
    font-size: 0.8rem;
    &.selected {
      font-weight: 600;
      color: var(--active-link);
    }
    & .icon {
      margin-right: 12px;
    }
  }
`;

const Items = [
  {
    icon: Home,
    label: "Home",
    href: "/",
  },
  {
    icon: Search,
    label: "Search",
    href: "/search",
  },
  {
    icon: LibraryBooks,
    label: "Your Library",
    href: "/library",
  },
];

const SideNavigation = ({ selected }) => {
  const { playerState, nextTrack, showQueue, showContextDrawer } =
    React.useContext(PlayerContext);
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const sub = AudioPlayerEvent.subscribe((time) => {
      setProgress(time);
    });
    return () => sub.unsubscribe();
  });

  return (
    <div className="SideNavigation">
      <LinkList>
        {Items.map((item, i) => {
          const Icon = item.icon;
          const className = (selected === i ? "selected " : "") + "flex center";
          return (
            <li key={i} className={className}>
              <Icon className="icon" />
              <Control.TextLink href={item.href}>{item.label}</Control.TextLink>
            </li>
          );
        })}
      </LinkList>
      <Box className="flex-bottom">
        <MusicCard
          showContextDrawer={showContextDrawer}
          {...playerState.selectedId}
          value={progress}
          nextTrack={nextTrack}
          playing={playerState.playing}
          ready={playerState.ready}
          duration={playerState.duration}
          muted={playerState.muted}
          showQueue={showQueue}
        />
      </Box>
    </div>
  );
};

SideNavigation.defaultProps = {};
export default SideNavigation;
