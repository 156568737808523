import React from "react";
import { updateTable } from "../../../connectors/skyTunesApiConnector";
// import { Analyser } from "../../../audio/AudioAnalyser";
import useAndroidConnect from "../../../hooks/useAndroidConnect";
import { PlayerContext } from "../../../hooks/usePlayerContext";
import { AudioEditEvent } from "../../../hooks/useSkyTunes";
import { mmss, playerUrl } from "../../../util/common";
import Observer from "../../../util/Observer";
import "./AudioPlayer.css";

export const AudioPlayerEvent = new Observer("audioout");
export const AudioRequestEvent = new Observer("audioin");

const AudioPlayer = () => {
  const playerRef = React.useRef(null);
  const [currentTrack, setCurrentTrack] = React.useState();
  const { androidNotify } = useAndroidConnect();
  const args = { controls: false, crossOrigin: "anonymous" };
  const {
    playerState,
    updatePlayerState,
    onNext,
    notify,
    openMini,
    sitrep,
    disconnect,
    connect,
    context,
  } = React.useContext(PlayerContext);
  const { track, queue, currentType, currentId } = playerState.selectedId ?? {
    track: {},
  };
  const { FileKey } = currentTrack || track;
  const src = !FileKey ? null : playerUrl(FileKey);

  const nextTrack = React.useCallback(() => {
    let currentIndex = queue.indexOf(track);
    if (currentIndex < queue.length) {
      currentIndex++;
      const queuedTrack = queue[currentIndex];
      // set progress value to 1 for nect track
      AudioPlayerEvent.next(1);
      console.log(" >> Sending next track signal %s", new Date().getTime());
      if (!!queuedTrack) {
        return (
          onNext &&
          onNext({
            queue,
            track: queuedTrack,
            previous: track,
            currentType,
            currentId,
          })
        );
      }
      updatePlayerState("selectedId", null);
      openMini(false);
    }
  }, [
    queue,
    track,
    onNext,
    updatePlayerState,
    currentType,
    currentId,
    openMini,
  ]);

  const eventRespond = React.useCallback(
    (event) => {
      const media = playerRef.current;
      if (!media) return;
      switch (event.type) {
        case "progress":
          media.currentTime = event.value;
          break;
        case "play":
          if (media.paused) {
            return media.play();
          }
          media.pause();
          break;
        case "mute":
          media.muted = !media.muted;
          updatePlayerState("muted", media.muted);
          break;
        default:
        //do nothing
      }
    },
    [updatePlayerState]
  );

  const saveTrackTime = React.useCallback(
    async (trackTime) => {
      const pkg = {
        ID: track.ID,
        trackTime,
      };
      sitrep(`Time updated to ${mmss(trackTime / 1000)}`);
      await updateTable("s3Music", pkg);
      AudioEditEvent.next();
      sitrep();
    },
    [track, sitrep]
  );

  React.useEffect(() => {
    const media = playerRef.current;
    if (!media) return;
    function handleLoad(e) {
      const currentTime = sessionStorage.getItem("currentTime");
      media.muted = playerState.muted;
      updatePlayerState("duration", media.duration);
      // connect(media);
      // if (context.state !== "running") {
      //   const k =
      //     window.confirm(`The equalizer needs permission to access your system.
      //   Click here to grant permission!!`);
      //   if (k) {
      //     context.resume();
      //   }
      //   //   return;
      // }

      media.play();
      !!track?.Title && notify(`Now Playing: ${track.Title}`);
      !!track?.Title && androidNotify(track);
      if (!track.trackTime || track.trackTime < 1000)
        saveTrackTime(media.duration * 1000);
      //
      if (!currentTime) return;
      media.currentTime = currentTime;
      sessionStorage.removeItem("currentTime");
    }

    function handleTime(e) {
      // only update every other second
      parseInt(media.currentTime) % 2 === 0 &&
        AudioPlayerEvent.next(media.currentTime);
    }

    function handlePlay(e) {
      updatePlayerState("playing", true);
    }

    function handleStop(e) {
      updatePlayerState("playing", false);
    }

    function handleDone(e) {
      console.log(
        " >> Track has ended [%s], %s",
        media.currentTime,
        new Date().getTime()
      );
      // disconnect();
      !!currentTrack && nextTrack();
      setCurrentTrack(null);
    }

    // console.log("Adding listeners");
    media.addEventListener("loadedmetadata", handleLoad);
    media.addEventListener("timeupdate", handleTime);
    media.addEventListener("ended", handleDone);
    media.addEventListener("pause", handleStop);
    media.addEventListener("play", handlePlay);
    // try {
    //   Analyser.attach(media, 400);
    // } catch (e) {
    //   console.log("ignoring errors");
    // }

    const sub = AudioRequestEvent.subscribe(eventRespond);
    !!track && track.ID !== currentTrack?.ID && setCurrentTrack(track);
    return () => {
      // console.log("Removing listeners");
      media.removeEventListener("loadedmetadata", handleLoad);
      media.removeEventListener("timeupdate", handleTime);
      media.removeEventListener("ended", handleDone);
      media.removeEventListener("pause", handleStop);
      media.removeEventListener("play", handlePlay);
      sub.unsubscribe();
    };
  }, [
    updatePlayerState,
    playerRef,
    playerState,
    track,
    currentTrack,
    nextTrack,
    eventRespond,
    notify,
    saveTrackTime,
    androidNotify,
    disconnect,
    connect,
  ]);

  if (!src) {
    return <i />;
  }

  return (
    <div className="AudioPlayer">
      <audio {...args} src={src} autoPlay ref={playerRef}>
        <source type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

AudioPlayer.defaultProps = {};
export default AudioPlayer;
